export const staggerChildren = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
        },
    }
}

export const staggerChild = {
    hidden: {
        opacity: 0,
        x: -50
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4
        }
    }
};

export const slideUpChild = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            // y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            // y: { stiffness: 1000 }
        }
    }
}
