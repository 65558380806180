import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgressbarWithChildren, buildStyles   } from 'react-circular-progressbar';
import useSound from 'use-sound';

import { ITimerState } from '../../globals/interfaces';
import { theme } from '../../Theme';
import DisplayPercent from './DisplayPercent';
import useTabVisible from '../../hooks/useTabVisible';
const sweep = require('../../assets/audio/astral-sweep.wav');
const coins = require('../../assets/audio/coins.wav');
//import { Button } from 'reactstrap';

interface Props {
    productivityGoal: number;
    productivityAchieved: number;
    backgroundColor: string;
    textColor: string;
    trailColor: string;
    customFontRem?: number;
    additionalText?: string;
    additionalJsx?: React.ReactNode;
    productivitySession?: boolean;
}

export default function StatusBarGraph ({
    productivityGoal,
    productivityAchieved,
    backgroundColor,
    textColor,
    trailColor,
    customFontRem,
    additionalText,
    additionalJsx,
    productivitySession
}: Props) {

    const visible = useTabVisible();

    const [previousGoal, setPreviousGoal] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [playSweep] = useSound(sweep, {
        volume: 0.02
    });
    const [playCoins] = useSound(coins, {
        volume: 0.07
    });

    useEffect(() => {
        if (previousGoal !== productivityGoal) {
            const percent = calcPercent(productivityAchieved, productivityGoal);
            setPercentage(percent);
            setPreviousGoal(productivityGoal);
        }
    }, [previousGoal, productivityGoal]);

    useEffect(() => {
        const percent = calcPercent(productivityAchieved, productivityGoal);
        if ((percent !== percentage) && visible) {
            if (productivitySession) {
                playSweep();
            }
            setTimeout(() => {
                if (productivitySession) {
                    playCoins();
                }
                setPercentage(percent);
            }, 2000);
        }
    }, [productivityAchieved, visible]);

    return (
        <Container
            customFontRem={customFontRem}
        >
            <CircularProgressbarWithChildren 
                strokeWidth={10}
                value={percentage}
                background
                backgroundPadding={6}
                styles={buildStyles({
                    pathColor: theme.colors.gold,
                    trailColor: trailColor,
                    pathTransitionDuration: 0.5,
                    backgroundColor: backgroundColor,
                    strokeLinecap: "round"
                })}
            >
                <DisplayPercent 
                    currentPercent={percentage} 
                    textColor={textColor} 
                    additionalText={additionalText}
                />
                {additionalJsx}
            </CircularProgressbarWithChildren>
        </Container>
    );
}

const calcPercent = (productivityAchieved: number, productivityGoal: number) => {
    const percent = Math.round(productivityAchieved / productivityGoal * 100);
    if (isNaN(percent)) {
        return 0;
    } 
    return percent;
}

const Container = styled.div<{customFontRem?: number}>`
    height: 100%;
    width:100%;
    font-size: ${p => p.customFontRem ? `${p.customFontRem}rem` : p.theme.fontSizes.xl};
`;
