import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Subtitle from '../Subtitle';
import Task from './Task';
import { ITask } from '../../globals/interfaces';
import logoImg from '../../assets/img/logo2.svg';
import ManageItems from '../ManageItems';
import useSounds from '../../hooks/useSound';
import { projectIdState, projectSelector } from '../Projects/atoms';
import { taskIdState } from './atoms';
import useUpdate from '../../hooks/useUpdate';
import { modalSessionState, switchTaskState } from '../ModalSession/atoms';
import ManageContainerBase from '../ManageContainer';
import { userDataState } from '../../App';

export default function Tasks () {
    console.log('TASKLIST RE-RENDER');

    const userData = useRecoilValue(userDataState);
    const [projectId, setProjectId] = useRecoilState(projectIdState);
    const selectedProject = useRecoilValue(projectSelector);
    const [taskId, setTaskId] = useRecoilState(taskIdState);
    const [modalSessionOpen, setModalSessionOpen] = useRecoilState(modalSessionState);
    const [switchTask, setSwitchTask] = useRecoilState(switchTaskState);
    const { handleUpdate, markComplete } = useUpdate();
    const [viewCompletedTasks, setViewCompletedTasks] = useState(false);


    const { playClick } = useSounds();

    const dueTodaySelected = selectedProject.name === 'Due Today';

    const renderTasks = selectedProject.tasks.length > 0;

    const isProjectSelect = projectId.length > 0;

    useEffect(() => {
        setTaskId('');
    }, [selectedProject._id]);

    const handleAddTask = async (e: any) => {
        e.preventDefault();
        // const success = await addTask(selectedProject._id, name);
        // if (success) {
        //     setAddingTask(false);
        //     setName('');
        // }
    };

    const handleSelectTask = (e: any, task: ITask) => {
        const taskId = task._id;
        if (projectId === 'dueToday') {
            setProjectId(task.projectId);
            setTaskId(taskId);
            setTimeout(() => {
                setTaskId(taskId);
            }, 500);
        } else {
            setTaskId(taskId);
        }
        if (switchTask) {
            playClick();
            setSwitchTask(false);
        }
        // if (e.currentTarget === e.target) {
        //     setTaskId(task._id);
        //     if (switchTask) {
        //         playClick();
        //         // setSwitchTask(false);
        //     }
        // }
    }

    return (
        <TasksContainer
            // onMouseEnter={() => setHover(true)}
            // onMouseLeave={() => setHover(false)}
        >
            <Subtitle
                name={
                    selectedProject && selectedProject.name
                        ? `Tasks ${dueTodaySelected ? '' : 'for'} ${
                              selectedProject.name
                          }`
                        : `Tasks`
                }
                backgroundColor={'rgb(204, 204, 206)'}
            />
            <TasksList
                isProjectSelect={isProjectSelect}
            >
                {isProjectSelect ? (
                    <div>
                        {!renderTasks && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { type: 'keyframes' },
                                }}
                                exit={{ opacity: 0 }}
                            >
                                <PlaceholderText>
                                    {
                                        dueTodaySelected ? 
                                        `Please add tasks by selecting a project
                                        from the Projects pane.` :
                                        `Click the + icon below to add tasks`
                                    }
                                </PlaceholderText>
                            </motion.div>
                        )}
                        <ListContainer
                            as={motion.div}
                            variants={variants}
                            initial={'hidden'}
                            animate={'show'}
                            key={projectId}
                        >
                            {renderTasks &&
                                selectedProject.tasks.map(
                                    (t: ITask, i: number) =>
                                        <Task
                                            task={t}
                                            taskId={taskId}
                                            projectId={projectId}
                                            viewCompletedTasks={viewCompletedTasks}
                                            switchTask={switchTask}
                                            index={i}
                                            totalProductivityTime={userData.stats.totalProductivityTime}
                                            modalSessionOpen={modalSessionOpen}
                                            handleSelectTask={handleSelectTask}
                                            handleUpdate={handleUpdate}
                                            markComplete={markComplete}
                                            setModalSessionOpen={setModalSessionOpen}
                                        />
                                )}
                        </ListContainer>
                        {
                            !dueTodaySelected && (
                                <ManageContainer>
                                    <ManageItems
                                        type={'Tasks'}
                                        viewCompleted={viewCompletedTasks}
                                        setViewCompleted={setViewCompletedTasks}
                                        switchTask={switchTask}
                                    />
                                </ManageContainer>

                            )
                        }
                    </div>
                ) : (
                    <LogoContainer />
                )}
            </TasksList>
        </TasksContainer>
    );
}

const TasksContainer = styled.div`
    height: 100%;
`;

const TasksList = styled.div<{isProjectSelect: boolean}>`
    padding: ${p => p.isProjectSelect ? '10px' : '0px'};
    height: calc(100% - 36px);
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
`;

const PlaceholderText = styled.div`
    font-size: ${p => p.theme.fontSizes.md};
`;

const LogoContainer = styled.div`
    background-image: url(${logoImg});
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
`;

const ManageContainer = styled(ManageContainerBase)`    
    ${TasksContainer}:hover & {
        opacity: 1;
    }
`;


const variants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
        },
    },
};

