import { useState, useEffect } from 'react';

function getScrollPercentage(element: any) {
    if (element === null) {
      return NaN;
    }
    const height = element.scrollHeight - element.clientHeight;
    return Math.round((element.scrollTop / height) * 100);
}

export default function useScroll () {
    const [scrollPercent, setScrollPercent] = useState(0);
    const handleScroll = (e: any) => {
        const percent = getScrollPercentage(e.target);
        setScrollPercent(percent);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return {
        scrollPercent
    }
}