import { atom, selector } from "recoil";
import { defaultTask } from "../../globals/defaults";
import { ITask } from "../../globals/interfaces";
import { projectSelector } from "../Projects/atoms";

export const taskIdState = atom<string>({
    key: 'taskIdId',
    default: ''
});

export const taskSelector = selector<ITask>({
    key: 'selectedTask',
    get: ({get}) => {
        const project = get(projectSelector);
        const taskId = get(taskIdState);
        return project.tasks.find(t => t._id === taskId) || defaultTask;
    } 
});

export const validTaskState = selector<boolean>({
    key: 'validTask',
    get: ({get}) => {
        const taskId = get(taskIdState);
        return taskId.length > 0;
    }
});
