import { 
    ITask, 
    IProductivityRecord,
    IProductivityEntry, 
    IProject, 
    ISaveAttachment,
    INote, 
    ISubtask,
    IReminderTask,
    ISessionTask,
} from './interfaces';

export const defaultTask: ITask = {
    _id: '',
    completed: false,
    name: '',
    projectId: '',
    notes: [],
    subtasks: [],
    attachments: []
}

export const defaultProductivityEntry: IProductivityEntry = {
    _id: '',
    productiveTime: 0,
    task: defaultTask
}

export const defaultProductivityRecord: IProductivityRecord = {
    _id: '',
    productivityGoal: 0,
    productivityAchieved: 0,
    entries: [],
    createdAt: ''
}

export const defaultProject: IProject = {
    _id: '',
    completed: false,
    name: '',
    tasks: []
}

export const defaultUserData = {
    _id: '', 
    productivityRecords: [], 
    weeklyProductivityGoal: 0, 
    email: '',
    projects: [],
    anonymous: true,
    workDays: 0,
    stats: {
        totalProductivityTime: 0,
        totalGoalTime: 0,
        highestProductivityScore: 0,
        highestProductivityTime: 0,
        longestCheckInStreak: 0,
        longestProductivityStreak: 0,
        checkIns: 0
    }
}

export const defaultReminderTask = {
    _id: '6375b30cc6ccaf85549b4f37',
    completed: false,
    name: 'Reminder me now',
    reminderDate: '11/21/2022 11:03 PM',
    projectId: '637433184a4e2a474c2c3be2',
    notes: [],
    subtasks: [],
    attachments: [],
}