import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import moment from 'moment';

import './index.css';
import Landing from './components/Landing/index';
import Todos from './components/Todos/index';
import { Suspense, useEffect } from 'react';
import StatusBar from './components/StatusBar/index';
import { addAccessTokenInterceptor } from './api/index';
import Loading from './components/Loading';
import Stats from './components/Stats';
import { atom, useSetRecoilState } from 'recoil';
import { IUserData } from './globals/interfaces';
import { defaultUserData } from './globals/defaults';
import useApi from './hooks/useApi';
import { projectsState } from './components/Projects/atoms';
import PrivacyPolicy from './components/PrivacyPolicy';

export const userDataState = atom<IUserData>({
    key: 'userData',
    default: defaultUserData
});

function App() {
    const { isLoading, error, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const { api } = useApi();

    const setUserData = useSetRecoilState(userDataState);
    const setProjects = useSetRecoilState(projectsState);

    useEffect(() => {
        const getUserData = async (user: any) => {
            const body = {
                name: user!.name,
                email: user!.email,
                today: moment().startOf('day')
            }

            const response = await api('post', '/user', body);

            if (response.successful) {
                const { projects } = response.data;
                setProjects(projects);
                delete response.data.projects;
                setUserData(response.data);
            }
        };

        if (isAuthenticated && user) {
            addAccessTokenInterceptor(getAccessTokenSilently);
            getUserData(user);
        }

    }, [isAuthenticated, user, getAccessTokenSilently]);

    

    if (error) {
        return <div>Sorry, there was an error. {error.message}</div>;
    }

    if (isLoading) {
        <Loading />;
    }

    return (
        <AppContainer data-test="app-container">
            {
                isAuthenticated && (
                    <StatusBar />
                )
            }
            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated === true ? (
                            <Navigate to="/todos" />
                        ) : (
                            <Landing />
                        )
                    }
                />
                <Route path='/todos' element={
                    <Suspense>
                        <Todos />
                    </Suspense>
                } />
                <Route path='/stats' element={
                    <Stats />
                } />
                <Route path='/privacy-policy' element={
                    <PrivacyPolicy />
                } />
            </Routes>
        </AppContainer>
    );
}

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export default App;
