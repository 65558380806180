import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useRecoilValue } from 'recoil';
// import { useWindowWidth } from '@react-hook/window-size';

import { ProjectsContainer } from './ProjectsContainer';
import ProjectsHeader from './ProjectsHeader';
import ProjectList from './ProjectsList';

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

function Projects () {

    const [showProjectsList, setShowProjectsList] = useState(true);

    useEffect(() => {
        function handleWindowResize() {
            const { innerWidth } = getWindowSize();
            if (innerWidth <= 1200) {
                setShowProjectsList(false);
            } else {
                setShowProjectsList(true);
            }
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <ProjectsContainer>
            <ProjectsHeader 
                showProjectsList={showProjectsList}
                setShowProjectsList={setShowProjectsList}
            />
            {/* <button onClick={() => test()}>test</button> */}
            <AnimatePresence>
                {
                    showProjectsList && (
                        <ProjectsListContainer
                            as={motion.div}
                            initial={{
                                height: 0,
                                opacity: 0,
                            }}
                            animate={{
                                height: "auto",
                                opacity: 1,
                            }}
                            exit={{
                                height: 0,
                                opacity: 0,
                            }}
                        >
                            <ProjectList />
                        </ProjectsListContainer>
                    )
                }
            </AnimatePresence>
        </ProjectsContainer>
    );
}

export default memo(Projects);

const ProjectsListContainer = styled.div`
`;
