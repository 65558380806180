import { setMaxListeners } from 'events';
import { useEffect, useState } from 'react';
import {
    Modal,
    ModalBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Button,
} from 'reactstrap';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';
import { PrimaryButton } from '../Buttons';

export const introModalState = atom({
    default: false,
    key: 'introModal',
});

const items = [
    {
        src: require('../../assets/img/directions-1.jpg'),
        caption: 'Set your Weekly Productivity Goal',
        key: 1,
    },
    {
        src: require('../../assets/img/directions-2.jpg'),
        caption: 'Set your Daily Productivity Goal',
        key: 2,
    },
    {
        src: require('../../assets/img/directions-4.gif'),
        caption: 'Add a Project',
        key: 3,
    },
    {
        src: require('../../assets/img/directions-5.gif'),
        caption: 'Add a Task',
        key: 4,
    },
    {
        src: require('../../assets/img/directions-6.gif'),
        caption: 'Start a Productivity Session',
        key: 5,
    },
];

export default function IntroModal() {
    const [open, setOpen] = useRecoilState(introModalState);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [reachedEnd, setReachedEnd] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    useEffect(() => {
        if (activeIndex === 4) {
            setReachedEnd(true);
        }
    }, [activeIndex, setReachedEnd]);

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.key}
            >
                <CustomImg src={item.src} width={'100%'} />
                <CarouselCaption
                    captionText={item.caption}
                    captionHeader={item.caption}
                />
            </CarouselItem>
        );
    });

    return (
        <Modal isOpen={open} size={'lg'}>
            <CustomModalBody>
                <h6>Welcome! Go through these slides to learn how to use Todo Plus:</h6>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    dark
                    interval={false}
                >
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </Carousel>
                <ButtonsContainer>
                    <PrimaryButton onClick={() => setOpen(false)}>I'm Ready</PrimaryButton>
                    <a
                        href={'https://www.youtube.com/watch?v=yG_Pq3Zoidk'}
                        target={'_blank'}
                        rel="noopener noreferrer"
                        style={{ marginLeft: '5px' }}
                    >
                        <Button
                            onClick={() => {}}
                            className={'btn-submit ml-2'}
                        >
                            Watch Full Tutorial
                        </Button>
                    </a>
                </ButtonsContainer>
            </CustomModalBody>
        </Modal>
    );
}

const CustomModalBody = styled(ModalBody)`
    text-align: center;
`;

const CustomImg = styled.img`
    max-height: 414px;
    width: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;
