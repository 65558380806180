import useSound from "use-sound";

const click = require('../assets/audio/click.mp3');

export default function useSounds () {
    const [playClick] = useSound(click, {
        volume: 0.25
    });

    return {
        playClick
    }
}