import { useState } from 'react';
import { Tooltip as ReactTooltip } from 'reactstrap';

interface Props {
    id: string;
    content: string;
}

export default function Tooltip ({
    id,
    content
}: Props) {

    const [open, setOpen] = useState(false);

    return (
        <ReactTooltip
            isOpen={open}
            toggle={() => setOpen(prev => !prev)}
            target={id}
        >
            {content}
        </ReactTooltip>
    );
}