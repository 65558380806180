import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { HashRouter, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Theme from './Theme';
import reportWebVitals from './reportWebVitals';

import { config } from './authConfig';
import history from './utils/history';

const { domain, clientId, audience } = config;

const onRedirectCallback = (appState: any) => {
    history.push(
        appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RecoilRoot>
        <HashRouter>
            <Auth0Provider
                domain={domain}
                clientId={clientId}
                audience={audience}
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                <Theme>
                    <App />
                </Theme>
            </Auth0Provider>
        </HashRouter>
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
