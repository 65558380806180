import React from 'react';
import styled from 'styled-components';

interface Props {
    backgroundColor: string;
    children: React.ReactNode;
}

export default function CustomRow ({
    backgroundColor,
    children
}: Props) {

    return (
        <Wrapper backgroundColor={backgroundColor}>
            <CenterRow>
                { children }
            </CenterRow>
        </Wrapper>
    );
}

const Wrapper = styled.div<Props>`
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: ${props => props.backgroundColor}
`;

const CenterRow = styled.div`
    width: 60%;
    margin-right: auto;
    margin-left: auto;
`;
