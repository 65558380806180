import React, { useState, useContext, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Nav, NavLink, TabContent, TabPane } from 'reactstrap';
import _ from 'lodash';

// Components:
import StatsGraphs from './StatsGraph';
import DetailedStats from './DetailedStats';
import Leaderboards from '../Leaderboards/index';
import { useRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { userDataState } from '../../App';

const Stats = () => {

    const { api } = useApi();
    const [userData, setUserData] = useRecoilState(userDataState);
    const { productivityRecords } = userData;
    console.log(userData, productivityRecords);
    //const { goalSuggestion } = goals;
    //const { updateAnonymousStatus } = actions;
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (tab: string) => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        if (activeTab === '1') {
            setRefreshKey(refreshKey => refreshKey + 1);
        }
    }, [activeTab]);

    const updateAnonymousStatus = async(): Promise<void> => {
        const anonymous = !userData.anonymous;
        const res = await api('put', '/user/anonymous', {
            _id: userData._id,
            anonymous
        });

        if (res.successful) {
            setUserData(prev => {
                const copy = _.cloneDeep(prev);
                copy.anonymous = anonymous;
                return copy;
            });
        }
    }
    
    return(
        <div>
            <div>
                <Nav tabs>
                    <NavLink
                        active={activeTab === '1'}
                        onClick={() => setActiveTab('1')}
                    >
                        Weekly Stats
                    </NavLink>
                    <NavLink
                        active={activeTab === '2'}
                        onClick={() => setActiveTab('2')}
                    >
                        Detailed Stats
                    </NavLink>
                    <NavLink
                        active={activeTab === '3'}
                        onClick={() => setActiveTab('3')}
                    >
                        Leaderboards
                    </NavLink>
                </Nav>
            </div>
            <div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'1'}>
                        <StatsGraphs 
                            productivityRecords={productivityRecords}
                            activeTab={activeTab}
                            refreshKey={refreshKey}
                        />
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <DetailedStats 
                            userData={userData}
                            goalSuggestion={''}
                            activeTab={activeTab}
                        /> 
                    </TabPane>
                    <TabPane tabId={'3'}>
                        <Leaderboards 
                            userData={userData}
                            updateAnonymousStatus={updateAnonymousStatus}
                            activeTab={activeTab}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Stats);