import React, { useState } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import { ISubtask, IProject, ITask, UpdateType } from '../../globals/interfaces';
import styled from 'styled-components';
import ActionIcon from '../ActionIcon';
import useUpdate from '../../hooks/useUpdate';

interface Props {
    subtask: ISubtask;
    projectId: string;
    taskId: string;
    viewCompletedsubtasks: boolean;
}

export default function Subtask ({
    subtask,
    projectId,
    taskId,
    viewCompletedsubtasks
}: Props) {

    const { handleUpdate, markComplete } = useUpdate();
    const [isHovered, setIsHovered] = useState<boolean>(false);

    if (!viewCompletedsubtasks && subtask.completed) {
        return null;
    }

    return (
        <Container
            as={motion.div}
            variants={variants}
            key={subtask._id}
            completed={subtask.completed}
        >
            <div>
                <Icon 
                    className={classnames('check-complete-task far', 
                        { 'fa-check-circle':  !subtask.completed && isHovered},
                        { 'fa-circle':  !subtask.completed && !isHovered},
                        { 'fa-check-circle': subtask.completed && !isHovered },
                        { 'fa-circle': subtask.completed && isHovered }
                    )}
                    style={{ display: 'inline-block' }} 
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    onClick={() => markComplete('subtask', !subtask.completed, projectId, taskId, subtask._id)}
                />
                <h6 style={{ display: 'inline-block' }}>{subtask.name}</h6>
            </div>
            <div>
                <ActionIcon 
                    type={'edit'}
                    onClick={() => handleUpdate('subtask', subtask.name, subtask._id)}
                    color={'white'}
                    hoverColor={'white'}
                />
            </div>
        </Container>
    );
}

const Icon = styled.i`
    margin-right: 5px;
`;

const Container = styled.div<{completed: boolean}>`
    display: flex;
    justify-content: space-between;
    opacity: ${p => p.completed && '0.5 !important'};
    h6 {
        text-decoration: ${p => p.completed && 'line-through !important'};
    }
`;

const variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            // y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            // y: { stiffness: 1000 }
        }
    }
};