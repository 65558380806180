import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CircularProgressbarWithChildren, buildStyles   } from 'react-circular-progressbar';
import _ from 'lodash';

import Backdrop from './Backdrop';
import useTimer from './useTimer';
import { timeFormatter } from '../../utils';
import { Icon } from '../Icon';
import StatusBarGraph from '../StatusBarGraph/index';
import TimerIcon from './TimerIcon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modalSessionState, switchTaskState } from './atoms';
import { taskSelector } from '../Tasks/atoms';
import { currentProductivityRecordState } from '../Todos/atoms';
import useApi from '../../hooks/useApi';
import { ISessionTask } from '../../globals/interfaces';
import { userDataState } from '../../App';

export default function ModalSession () {
    console.log('ModalSession RERENDER');

    const [userData, setUserData] = useRecoilState(userDataState);
    const [modalSessionOpen, setModalSessionOpen] = useRecoilState(modalSessionState);
    const selectedTask = useRecoilValue(taskSelector);
    const [switchTask, setSwitchTask] = useRecoilState(switchTaskState);
    const [currentProductivityRecord, setCurrentProductivityRecord] = useRecoilState(currentProductivityRecordState);
    const { productivityGoal, productivityAchieved } = currentProductivityRecord;
    const { api } = useApi();
    
    const createProductivityEntry = async(sessionTasks: Array<ISessionTask>): Promise<void> => {
        const data = {
            productivityRecord: currentProductivityRecord,
            sessionTasks,
            userId: userData._id,
            stats: userData.stats
        };

        const response = await api('post', '/productivity', {data});
        
        if (response.successful) {
            const { updatedProductivityRecord, updatedStats } = response.data;
            setCurrentProductivityRecord(updatedProductivityRecord);
            setUserData(prev => {
                const copy = _.cloneDeep(prev);
                copy.stats = updatedStats;
                return copy;
            });
            new Notification('Todo Plus', {
                body: 'Productivity Session Completed!',
                icon: process.env.PUBLIC_URL + '/assets/img/icon.png'
            });
        }
    };

    const toggle = () => setModalSessionOpen(!modalSessionOpen);

    const { 
        timerState,
        totalSeconds,
        currentSeconds,
        handleTimer,
        skipBreak,  
        handleSwitchTask,
        handleCancelSession,
        confirmCancel,
        exitCancelSession
    } = useTimer(
        modalSessionOpen, 
        selectedTask,
        toggle, 
        setSwitchTask,
        createProductivityEntry
    );

    const [frontAnimation, setFrontAnimation] = useState(false);
    const handleActions = (fn: any) => {
        if (timerState === 'BREAK COMPLETE') {
            setFrontAnimation(true);
        }
        fn();
    }

    useEffect(() => {
        if (frontAnimation) {
            setTimeout(() => {
                setFrontAnimation(false);
            }, 2000);
        }
    }, [frontAnimation]);

    const VALID_SESSION = ['RUNNING SESSION', 'START SESSION', 'PAUSE SESSION'].includes(timerState);
    const TIMER_TASK = VALID_SESSION ? 
        selectedTask.name :
        timerState === 'RUNNING BREAK' ?
        'Break' : null;

    const SessionCompletedJsx = () => {
        return (
            <SessionCompletedIconsContainer>
                <TimerIcon 
                    className='fas fa-coffee text-dark'
                    onClick={() => handleActions(handleTimer(true))}
                    dataTip={'Start Break'}
                    id={'startBreak'}
                />
                <TimerIcon 
                    className='fas fa-hourglass-half text-dark'
                    onClick={() => handleActions(skipBreak)}
                    dataTip={'Skip Break'}
                    id={'skipBreak'}
                />
            </SessionCompletedIconsContainer>
        );
    }

    const handleCloseModal = () => {
        if (!VALID_SESSION) {
            toggle();
            setSwitchTask(false);
        }
    }

    const animateVariant = (modalSessionOpen && !switchTask) ?
        'visible' :
        'exit';

    return (
        <Backdrop
            onClick={() => handleCloseModal()}
            switchTask={switchTask}
        >
            <Container
                as={motion.div}
                variants={dropIn}
                initial={'hidden'}
                animate={animateVariant}
                onClick={(e: any) => e.stopPropagation()}
                switchTask={switchTask}
            >
                <Body>
                    <Circle timerState={timerState}>
                        <Content timerState={timerState}>
                            <Front
                                as={motion.div}
                                frontAnimation={frontAnimation}
                            >
                                <CircularProgressbarWithChildren 
                                    strokeWidth={10}
                                    value={((totalSeconds - currentSeconds) / totalSeconds) * 100}
                                    background
                                    backgroundPadding={6}
                                    styles={buildStyles({
                                        pathColor: `#f2b632`,
                                        pathTransitionDuration: 0.5,
                                        backgroundColor: "#e9e6e6",
                                        strokeLinecap: "round",
                                    })}
                                >
                                    <CircleText>
                                        {
                                            timerState === 'CANCEL SESSION' ?
                                                <TimerContainer>
                                                    <TaskName>Cancel Session?</TaskName>
                                                    <WarningIcon 
                                                        className={'fas fa-exclamation-triangle'}
                                                        as={motion.i}
                                                        animate={{ scale: 1.25 }}
                                                        transition={{ type: "spring", bounce: 0.5 }}
                                                    /> 
                                                    <ConfirmCancelButton 
                                                        as={motion.button}
                                                        className={'btn btn-danger'}
                                                        onClick={() => confirmCancel()}
                                                    >
                                                        Yes, cancel
                                                    </ConfirmCancelButton>
                                                    <motion.button 
                                                        className={'btn btn-submit mt-2'}
                                                        whileHover={{ scale: 1.1 }}
                                                        onClick={() => exitCancelSession()}
                                                    >
                                                        No, resume
                                                    </motion.button>
                                                </TimerContainer> :
                                            timerState === 'SESSION COMPLETE' ? 
                                                <CheckIcon 
                                                    className={'fas fa-check'}
                                                    as={motion.i}
                                                    animate={{ scale: 1.75 }}
                                                    transition={{ type: "spring", bounce: 0.5 }}
                                                />  :
                                            timerState === 'BREAK COMPLETE' ? 
                                                <TimerContainer>
                                                    <TaskName>Break over</TaskName>
                                                    <TimerIcon 
                                                        className={'fas fa-hourglass-half text-dark mx-auto'}
                                                        dataTip={'Start new session'}
                                                        id={'start'}
                                                        onClick={() => handleTimer()}
                                                    />
                                                </TimerContainer> :
                                            <TimerContainer>
                                                <TaskName>{TIMER_TASK}</TaskName>
                                                <Timer>{timeFormatter(currentSeconds)}</Timer>
                                                {
                                                    VALID_SESSION && (
                                                        <ButtonsContainer>
                                                            <TimerIcon 
                                                                className='fas fa-times'
                                                                onClick={() => handleCancelSession()}
                                                                dataTip={'Cancel'}
                                                                id={'cancel'}
                                                                hoverColor={'red'}
                                                            />
                                                            <TimerIcon 
                                                                className={timerState !== 'RUNNING SESSION' ? 'fas fa-play' : 'fas fa-pause'}
                                                                dataTip={timerState !== 'RUNNING SESSION' ? 'Start' : 'Pause'}
                                                                id={'playPause'}
                                                                onClick={() => handleTimer()}
                                                            />
                                                            <TimerIcon 
                                                                className='fas fa-exchange-alt'
                                                                dataTip={'Change Task'}
                                                                id={'changeTask'}
                                                                onClick={() => handleSwitchTask()}
                                                            />
                                                        </ButtonsContainer>
                                                    )
                                                }
                                            </TimerContainer>
                                        }
                                    </CircleText>                  
                                </CircularProgressbarWithChildren>
                            </Front>
                            <Back frontAnimation={frontAnimation}>
                                <StatusBarGraph 
                                    productivityGoal={productivityGoal}
                                    productivityAchieved={productivityAchieved}
                                    backgroundColor={'white'}
                                    textColor={'black'}
                                    trailColor={'#e4e2dd'}
                                    customFontRem={3}
                                    additionalText={`of today's goal`}
                                    additionalJsx={<SessionCompletedJsx />}
                                    productivitySession={true}
                                />
                            </Back>
                        </Content>
                    </Circle>
                </Body> 
            </Container>
        </Backdrop>
    );
}

const TimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const TaskName = styled.p`
    font-size: ${p => p.theme.fontSizes.md};
    text-align: center;
    margin: 0;
`;

const Timer = styled.p`
    font-size: 4rem;
    margin-top: 10px;
    margin-bottom: 12px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;
    margin-top: 5px;
`;

const Container = styled.div<{switchTask: boolean}>`
    width: 520px;
    height: 626px;
    border-radius: 15px;
    background-color: none;
    position: fixed;
    padding: 10px;
`;

const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const Circle = styled.div<{timerState: string}>`
    border-radius: 50%;
    width: 500px;
    height: 500px;
    /* perspective: 466px; */
    /* position: absolute; */

    @keyframes shadow-pulse {
        0% {
            box-shadow: 0 0 0 0px rgba(242, 182, 50, 1);
        }

        100% {
            box-shadow: 0 0 0 50px rgba(30,144,255, 0);
        }
    }

    animation: ${p => p.timerState === 'SESSION COMPLETE' && 'shadow-pulse 1s'};

`;

const Content = styled.div<{timerState: string}>`
    @keyframes flip {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(-180deg);
        }
    }
    width: 100%;
    height: 100%;
    border-radius: 50%;

    will-change: transform;
    transition: transform 1s;
    transform-style: preserve-3d;

    animation: ${p => (p.timerState === 'SESSION COMPLETE') && 'flip 600ms forwards'};
    animation-delay: 1s;

    /* ${Circle}:hover & {
        transform: rotateY(180deg);
        transition: transform 0.5s;
    } */
`;

const Front = styled.div<{frontAnimation: boolean}>`
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 50%;
    backface-visibility: hidden;

    @keyframes fade {
        from {  
            opacity: 0;
        }       
        to {
            opacity: 1;
        }
    }

    animation: ${p => p.frontAnimation && 'fade 300ms'};
`;

const Back = styled(Front)`
    background: white;
    color: white;
    transform: rotateY( 180deg );
    animation: none;
`;

const CheckIcon = styled(Icon)`
    font-size: 150px;
    color: ${p => p.theme.colors.darkBlue};
`;

const WarningIcon = styled(Icon)`
    font-size: 90px;
    color: ${p => p.theme.colors.darkBlue};
    margin-top: 25px;
    margin-bottom: 25px;
`;

const CircleText = styled.div`
    font-size: 3rem;
`;

const ConfirmCancelButton = styled.button`
    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-3px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(3px, 0, 0);
        }
    }

    &:hover {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
`;

const SessionCompletedIconsContainer = styled.div`
    display: flex;
    gap: 30px;
    margin-top: 18px;
`;

const dropIn = {
    hidden: {
        y: '-100vh',
        opacity: 0
    },
    visible: {
        y: '0',
        opacity: 1,
        transition: {
            duration: 0.1,
            type: 'spring',
            damping: 25,
            stiffness: 500
        }
    },
    exit: {
        y: '100vh',
        opacity: 0
    }
}