import Timer from 'easytimer.js';
import { useState, useEffect } from 'react';
import useSound from 'use-sound';

import { ITask, ISessionTask, ITimerState } from '../../globals/interfaces';
import useSessionTasks from './useSessionTasks';
import useTabVisible from '../../hooks/useTabVisible';

const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV;
const start = require('../../assets/audio/start.mp3');
const pause = require('../../assets/audio/pause.mp3');
const sessionComplete = require('../../assets/audio/session-complete.mp3');
const breakComplete = require('../../assets/audio/break-complete.mp3');
const startCancelSound = require('../../assets/audio/start-cancel.mp3');
const confirmCancelSound = require('../../assets/audio/confirm-cancel.mp3');
const switchTaskSound = require('../../assets/audio/switch-task.mp3');

export default function useTimer(
    modalSessionOpen: boolean,
    selectedTask: ITask,
    toggle: () => void,
    setSwitchTask: React.Dispatch<React.SetStateAction<boolean>>,
    createProductivityEntry: (sessionTasks: Array<ISessionTask>, currentSeconds: number) => void
) {
    
    const [timerState, setTimerState] = useState<ITimerState>('START SESSION');
    const testSeconds = timerState === 'RUNNING BREAK' ?
        3 : 5;
    const productionSeconds = timerState === 'RUNNING BREAK'
        ? 300 : 1500;
    const totalSeconds = REACT_APP_NODE_ENV === 'production' ? 
        productionSeconds : testSeconds;

    const [timer, setTimer] = useState(new Timer({ countdown: true, startValues: { seconds: totalSeconds } }));

    const visible = useTabVisible();

    const [playStart] = useSound(start, {
        volume: 0.25,
    });
    const [playPause] = useSound(pause, {
        volume: 0.15,
    });
    const [playSessionComplete] = useSound(sessionComplete, {
        volume: 0.4,
    });
    const [playBreakComplete] = useSound(breakComplete, {
        volume: 0.3,
    });
    const [playStartCancel] = useSound(startCancelSound, {
        volume: 0.2,
    });
    const [playConfirmCancel] = useSound(confirmCancelSound, {
        volume: 0.2,
    });
    const [playSwitchTask] = useSound(switchTaskSound, {
        volume: 0.4,
    });
    const [currentSeconds, setCurrentSeconds] = useState(totalSeconds);
    const [sessionSwitch, setSessionSwitch] = useState(false);
    const sessionTasks = useSessionTasks(
        selectedTask, 
        currentSeconds, 
        totalSeconds,
        timerState  
    );

    useEffect(() => {
        timer.reset();
        timer.stop();
        setTimerState('START SESSION');
    }, [modalSessionOpen]);

    useEffect(() => {
        if (sessionSwitch) {
            timer.reset();
            timer.stop();

            if (timerState === 'RUNNING SESSION') {
                playSessionComplete();
                console.log(sessionTasks);
                createProductivityEntry(sessionTasks, currentSeconds);
            } else if (timerState === 'RUNNING BREAK') {
                playBreakComplete();
                setTimerState('BREAK COMPLETE');
            }
        }
    }, [sessionSwitch]);

    useEffect(() => {
        if (timerState === 'RUNNING SESSION' && sessionSwitch && visible) {
            setTimerState('SESSION COMPLETE');
        }
    }, [sessionSwitch, visible, timerState]);

    timer.addEventListener('secondsUpdated', function () {
        setCurrentSeconds(timer.getTotalTimeValues().seconds);
    });

    timer.addEventListener('targetAchieved', function () {
        setSessionSwitch(true);
    });

    const handleTimer = (startBreak?: boolean): void => {
        setSessionSwitch(false);
        // not handling PAUSE BREAK for now
        if (timerState === 'RUNNING SESSION') {
            setTimerState('PAUSE SESSION');
            timer.pause();
            playPause();
        } else {
            if (startBreak) {
                setTimerState('RUNNING BREAK');
            } else {
                setTimerState('RUNNING SESSION');
            }

            // necessary if switch task activated immediately
            if (currentSeconds === totalSeconds) {
                timer.reset();
            }
            
            timer.start();
            playStart();
        }
    };

    const skipBreak = () => {
        timer.reset();
        timer.start();
        setTimerState('RUNNING SESSION');
        setCurrentSeconds(totalSeconds);
        setSessionSwitch(false);
        playStart();
    };

    const handleSwitchTask = () => {
        playSwitchTask();
        timer.pause();
        setSwitchTask(true);
        setTimerState('PAUSE SESSION')
    }

    const handleCancelSession = () => {
        playStartCancel();
        timer.pause();
        setTimerState('CANCEL SESSION');
    }

    const confirmCancel = () => {
        toggle();
        playConfirmCancel();
    }

    const exitCancelSession = () => {
        setTimerState('PAUSE SESSION');
    }

    return {
        timerState,
        totalSeconds,
        currentSeconds,
        handleTimer,
        skipBreak,  
        handleSwitchTask,
        handleCancelSession,
        confirmCancel,
        exitCancelSession
    };
}
