import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { IMap, IUserData } from '../../globals/interfaces';
import useApi from '../../hooks/useApi';
import { timeFormatter } from '../../utils';
import { 
    resolveCumulativeProductivityScore, 
    resolveHighestProductiveScoreSingleDay,
    resolveHighestProductiveHoursSingleDay 
} from '../Stats/functions';
import { IDataMapStructure } from './interfaces';

export default function useLeaderboardsData () {
    const [calcCompleted, setCalcCompleted] = useState(false);
    const [allUsersData, setAllUsersData] = useState<Array<IUserData>>([]);
    const [dataMap, setDataMap] = useState<IDataMapStructure>({
        totalProductivityTime: {
            name: 'Total Productivity Time', 
            type: 'TIME', 
            data: []
        },
        overallProductivityScore: {
            name: 'Overall Productivity Score',
            type: 'SCORE',
            data: []
        },
        highestProductivityTime: {
            name: 'Highest Productivity Time (single day)',
            type: 'TIME',
            data: []
        },
        highestProductivityScore: {
            name: 'Highest Productivity Score (single day)',
            type: 'SCORE',
            data: []
        },
        avgDailyProductivityTime: {
            name: 'Average Daily Productivity Time',
            type: 'TIME',
            data: []
        },
        longestProductivityStreak: {
            name: 'Longest Productivity Streak',
            type: 'STREAK',
            data: []
        },
        longestCheckInStreak: {
            name: 'Longest Check-in Streak',
            type: 'STREAK',
            data: []
        }
    });

    const { api } = useApi();

    useEffect(() => {
        const getAllUsersData = async () => {
            const res = await api('get', '/users');
            if (res.successful) {
                setAllUsersData(res.data);
                setCalcCompleted(true);

                const _dataMap = _.cloneDeep(dataMap);
                const data: Array<IUserData> = res.data;
                for (let i = 0; i < data.length; i++) {
                    const { email, anonymous, stats } = data[i];
                    const username = anonymous ? 'Anonymous' : email.split('@')[0];

                    _dataMap.totalProductivityTime.data.push({
                        username,
                        stat: stats.totalProductivityTime,
                        email
                    });

                    _dataMap.overallProductivityScore.data.push({
                        username,
                        stat: stats.totalProductivityTime / stats.totalGoalTime,
                        email
                    });

                    _dataMap.avgDailyProductivityTime.data.push({
                        username,
                        stat: stats.totalProductivityTime / stats.checkIns,
                        email
                    });

                    _dataMap.longestProductivityStreak.data.push({
                        username,
                        stat: stats.longestProductivityStreak,
                        email
                    });

                    _dataMap.longestProductivityStreak.data.push({
                        username,
                        stat: stats.longestCheckInStreak,
                        email
                    });
                }

                for (const key in _dataMap) {
                    // @ts-ignore
                    _dataMap[key].data.sort((a, b) => b.stat - a.stat);
                }

                setDataMap(_dataMap);
            }
        }
        if (!calcCompleted) {
            getAllUsersData();
        }
    }, [calcCompleted]);

    const highestCumulativeProductivityScores = useMemo(() => {
        const totalProductivityScores = [], 
            weekProductivityScores = [], 
            highestTotalAverageProductiveHours = [],
            highestWeekAverageProductiveHours = [];
        
        for (let i = 0; i < allUsersData.length; i++) {
            const entry = resolveCumulativeProductivityScore(allUsersData[i]);
            const { 
                totalProductivityScore, 
                weekProductivityScore, 
                totalAverageProductiveHours, 
                weekAverageProductiveHours 
            } = entry;

            const username = allUsersData[i].email;
            const { anonymous } = allUsersData[i];

            const totalScore = {
                totalProductivityScore,
                username,
                anonymous
            }

            totalProductivityScores.push(totalScore);

            const weekScore = {
                weekProductivityScore,
                username,
                anonymous
            }

            weekProductivityScores.push(weekScore);

            const totalHours = {
                totalAverageProductiveHours,
                username,
                anonymous
            }

            highestTotalAverageProductiveHours.push(totalHours);

            const weekHours = {
                weekAverageProductiveHours,
                username,
                anonymous
            }

            highestWeekAverageProductiveHours.push(weekHours);

        }

        totalProductivityScores.sort((a, b) => b.totalProductivityScore - a.totalProductivityScore); 
        weekProductivityScores.sort((a, b) => b.weekProductivityScore - a.weekProductivityScore); 
        highestTotalAverageProductiveHours.sort((a, b) => Number(b.totalAverageProductiveHours) - Number(a.totalAverageProductiveHours));
        highestWeekAverageProductiveHours.sort((a, b) => Number(b.weekAverageProductiveHours) - Number(a.weekAverageProductiveHours)); 
        
        return {
            totalProductivityScores,
            weekProductivityScores,
            highestTotalAverageProductiveHours,
            highestWeekAverageProductiveHours
        }

    }, [allUsersData]);

    const highestProductivityScoresInADay = useMemo(() => {
        const scores = [];
        for (let i = 0; i < allUsersData.length; i++) {
            const entry: IMap<any> = resolveHighestProductiveScoreSingleDay(allUsersData[i]);
            entry.username = allUsersData[i].email;
            entry.anonymous = allUsersData[i].anonymous;
            scores.push(entry);
        }
        scores.sort((a, b) => Number(b.highestProductivityScore) - Number(a.highestProductivityScore));
        return scores;
    }, [allUsersData]);

    const highestProductiveHoursInADay = useMemo(() => {
        const hours = [];
        for (let i = 0; i < allUsersData.length; i++) {
            const entry: IMap<any> = resolveHighestProductiveHoursSingleDay(allUsersData[i]);
            entry.username = allUsersData[i].email;
            entry.anonymous = allUsersData[i].anonymous;
            hours.push(entry);
        }
        hours.sort((a, b) => Number(b.highestProductivityScore) - Number(a.highestProductivityScore));
        return hours;
    }, [allUsersData]);

    return {
        dataMap
    }
}