export default function usePermissions () {
    const permission = Notification.permission === "granted";

    const handleRequestPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission) {
            // NotificationManager.success('Thank you!');
        } else {
            alert('You will not be notified when you complete productivity sessions.')
        }
    }

    return {
        permission,
        handleRequestPermission
    };
}