import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Table as ReactTable } from 'reactstrap';

import { formatPercent, properCase, timeFormatter } from '../../utils';
import { IDataMapProps, IMapping, LeaderboardDataItem, StatType } from '../Leaderboards/interfaces';
import { IUserData } from '../../globals/interfaces';

interface Props {
    data: any;
    type: StatType;
    userData: IUserData;
}

export default function Table ({
    data,
    type,
    userData
}: Props) {

    const resolveValue = (stat: number, type: StatType) => {
        if (type === 'SCORE') {
            return (isNaN(stat) || stat === Infinity )? '' : formatPercent(stat);
        } else if (type === 'TIME') {
            return timeFormatter(stat);
        } else {
            return stat;
        }
    }

    const resolveUsername = (email: string, username: string) => {
        if (email === userData.email) {
            if (userData.anonymous) {
                return 'Anonymous';
            }
            return userData.email.split('@')[0];
        }
        return username;
    }

    return (
        <ReactTable
            bordered
            striped
        >
            <thead>
                <tr>
                    <th style={{ width: '50%' }}>Username</th>
                    <th style={{ width: '50%' }}>{properCase(type)}</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((d: LeaderboardDataItem, i: number) => (
                        <tr key={i}>
                            <td>{resolveUsername(d.email, d.username)}</td>
                            <td>{resolveValue(d.stat, type)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </ReactTable>
    );
}
