import { useState, useEffect } from 'react';
import moment from 'moment';
import { clearInterval, setInterval } from 'worker-timers';
import { useRecoilValue } from 'recoil';
import { modalSessionState } from '../components/ModalSession/atoms';

export enum TimerType {
    SECONDS = 1000,
    MINUTES = 60000
}

export default function useWorkTimer (type: TimerType = TimerType.SECONDS) {
    const modalSessionOpen = useRecoilValue(modalSessionState);
    const [dateTime, setDateTime] = useState<Date>(new Date());
    const [workTimeAvailable, setWorkTimeAvailable] = useState<string>('');
    const [eosReload, setEodReload] = useState(false);

    useEffect(() => {
        const workTimeSetter = () => {
            const eod = moment().hour(24).minute(0).second(0);

            const timeToEnd = eod.subtract({ 
                hours: Number(moment().format('HH')), 
                minutes:Number(moment().format('mm')), 
                seconds: Number(moment().format('ss')) 
            });

            const workTimeAvailableFormatted = moment(timeToEnd).format('HH:mm:ss');
            setWorkTimeAvailable(workTimeAvailableFormatted);
            const eodSeconds = moment.duration(moment(timeToEnd).format('HH:mm:ss'), 'seconds').asSeconds();
            // console.log(eodSeconds);
            if (eodSeconds === 84280) {
                setEodReload(true);
            }
        };
        
        const interval = setInterval(() => {
            workTimeSetter();
            setDateTime(new Date());
        }, type);

        return () => {
            clearInterval(interval);
        };
    }, [type]);

    useEffect(() => {
        if (eosReload && !modalSessionOpen) {
            window.location.reload();
        }
    }, [eosReload, modalSessionOpen]);

    return {
        dateTime,
        workTimeAvailable
    }

}