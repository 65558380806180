import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from "react-router-dom";
import { motion } from 'framer-motion';
import styled from "styled-components";

export default function Avatar () {

    const { user, logout } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
        returnTo: window.location.origin,
    });

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(prev => !prev);

    let initials = '';

    if (user && user.name) {
        const parts = user.name.split(' ');
        parts.map(p => initials += p[0]);
    } else if (user && user.email) {
        initials += user.email[0];
    }

    if (!user) {
        return null;
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <Container
                dropdownOpen={dropdownOpen}
            >
                <DropdownToggle style={{ background: 'none', border: 'none', boxShadow: 'none', color: 'black', fontSize: '1.75rem' }}>
                    {initials.toUpperCase()}
                </DropdownToggle>
            </Container>
            <DropdownMenu>
                <DropdownItem>
                    <NavLink
                        tag={RouterNavLink}
                        to={'/todos'}
                        activeClassName="custom-active"
                    >
                        Todos
                    </NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink
                        tag={RouterNavLink}
                        to={'/stats'}
                        activeClassName="custom-active"
                    >
                        Stats
                    </NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink 
                        tag={RouterNavLink}
                        to={''}
                        activeClassName="router-link-exact-active"
                        onClick={() => logoutWithRedirect()}
                    > 
                        Logout
                    </NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink 
                        tag={RouterNavLink}
                        to={'/privacy-policy'}
                        activeClassName="router-link-exact-active"
                    > 
                        Privacy Policy
                    </NavLink>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>

    );
}

const Container = styled(motion.div)<{dropdownOpen: boolean}>`
    height: ${p => p.dropdownOpen ? '66px' : '60px'};
    width: ${p => p.dropdownOpen ? '66px' : '60px'};
    font-size: ${p => p.dropdownOpen ? '1.925rem' : '1.75rem'};
    border-radius: 50%;
    background-color: white;
    color: black;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms;
    will-change: transform;

    &:hover {
        cursor: pointer;
        height: 66px;
        width: 66px;
        font-size: 1.925rem;
    }
`;
