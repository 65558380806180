import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import SlideUpContainer from '../SlideUpContainer';
import PercentageStat from './PercentageStat';
import { IUserData } from '../../globals/interfaces';
import { formatPercent, timeFormatter } from '../../utils';

interface Props {
    userData: IUserData,
    goalSuggestion: number | string
    activeTab: string;
}

export default function DetailedStats ({
    userData,
    goalSuggestion,
    activeTab
}: Props) {

    const {
        totalProductivityTime,
        totalGoalTime,
        highestProductivityTime,
        highestProductivityScore,
        longestProductivityStreak,
        longestCheckInStreak,
        checkIns
    } = userData.stats;

    const formatScore = (score: number) => (score * 100).toFixed(2);

    return (
        <SlideUpContainer
            useKey={activeTab}
        >
            <Container>
                <PercentageStat 
                    value={formatScore(totalProductivityTime / totalGoalTime)}
                    name={'Total Productivity Score:'}
                    description={'all productivity achieved / all goals set.'}
                    percentage={true}
                />
                {/* <PercentageStat
                    value={weekProductivityScore}
                    name={`Current Week's Productivity Score:`}
                    description={`productivity achieved / goals set for the last ${userData.workDays} days.`}
                    percentage={true}
                /> */}
                <PercentageStat 
                    value={formatScore(highestProductivityScore)}
                    name={`Highest Productivity Score in a single day`}
                    description={''}
                    percentage={true}
                />
                {/* <PercentageStat 
                    value={weekAverageProductiveHours}
                    name={`Current Week's Average Daily Productive Hours (${userData.workDays} days)`} 
                    description={`(suggestion to meet weekly goal: ${goalSuggestion})`}
                    percentage={false}
                /> */}
                <PercentageStat 
                    value={timeFormatter(highestProductivityTime)}
                    name={`Highest Productive Time in a single day:`} 
                    description={''}
                    percentage={false}
                />
                <PercentageStat 
                    value={timeFormatter(totalProductivityTime)}
                    name={'Total Productive Time'}
                    description={''}
                    percentage={false}
                />
                <PercentageStat 
                    value={timeFormatter(totalProductivityTime / checkIns)}
                    name={'Total Average Daily Productive Time'}
                    description={''}
                    percentage={false}
                />
                <PercentageStat 
                    value={longestProductivityStreak}
                    name={'Longest Productivity Streak'}
                    description={''}
                    percentage={false}
                />
                <PercentageStat 
                    value={longestCheckInStreak}
                    name={'Longest Check-in Streak'}
                    description={''}
                    percentage={false}
                />
            </Container>
        </SlideUpContainer>
    );
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 10px;
    padding: 10px 10px;
`;
