import React, { useMemo, useState, useEffect, useRef } from 'react';
import { animate } from 'framer-motion';

import { ITimerState } from '../../globals/interfaces';

const DELAY = 4000;

interface DisplayPercentProps {
    currentPercent: number;
    textColor: string;
    additionalText?: string;
}

export default function DisplayPercent ({
    currentPercent,
    textColor,
    additionalText
}: DisplayPercentProps) {
    const nodeRef = useRef(null);
    const [previousPercent, setPreviousPercent] = useState(0);

    useEffect(() => {
        const node = nodeRef.current;

        const controls = animate(previousPercent, currentPercent, {
            duration: 1.5,
            onUpdate: (value) => {
                // @ts-ignore
                node.textContent = `${value.toFixed(0)}%`;
            }
        });

        setTimeout(() => {
            setPreviousPercent(currentPercent);
        }, DELAY);

        return () => controls.stop();
    // eslint-disable-next-line
    }, [currentPercent]);

    const displayAdditionalText = additionalText && additionalText.length > 0;

    return (
        <>
            <span ref={nodeRef} style={{ color: textColor }}></span>
            {
                displayAdditionalText && (
                    <span style={{ color: textColor, fontSize: '1rem' }}>{additionalText}</span>
                )
            }
        </>
    );

}
