import { useCallback, useEffect, useState } from "react";

export default function useTabVisible() {
    const [visible, setVisible] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setVisible(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [handleVisibilityChange]);

    return visible;
}