import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import {
    ITask,
    INote,
    IProject,
    HandleUpdate,
    UpdateType,
    MarkComplete,
} from '../../globals/interfaces';
import styled from 'styled-components';
import ActionIcon from '../ActionIcon';
import HourGlassArrow from './HourGlassArrow';
// import ActionIcon from './ActionIcon';

interface Props {
    task: ITask;
    taskId: string;
    projectId: string;
    viewCompletedTasks: boolean;
    switchTask: boolean;
    index: number;
    totalProductivityTime: number;
    modalSessionOpen: boolean;
    handleSelectTask: (e: any, task: ITask) => void;
    handleUpdate: HandleUpdate;
    markComplete: MarkComplete;
    setModalSessionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Task({
    task,
    taskId,
    projectId,
    viewCompletedTasks,
    switchTask,
    index,
    totalProductivityTime,
    modalSessionOpen,
    handleSelectTask,
    handleUpdate,
    markComplete,
    setModalSessionOpen,
}: Props) {
    const [isHovered, setIsHovered] = useState(false);
    const istaskId = taskId === task._id;

    const handleMarkComplete = () => {
        markComplete('task', !task.completed, projectId, task._id);
    };

    const variant = switchTask ? 'scale' : task._id ? 'show' : 'hidden';

    const controls = useAnimation();

    useEffect(() => {
        if (istaskId && !modalSessionOpen) {
            controls.start({
                scale: [1, 1.3, 1.3, 1, 1],
                rotate: [0, 0, 360, 360, 0],
                transition: {
                    duration: 2,
                    repeat: Infinity,
                    ease: 'linear',
                    repeatDelay: 3,
                },
            });
        } else {
            controls.stop();
        }
    }, [istaskId, modalSessionOpen, controls]);

    const renderHourglassArrow =
        index === 0 && istaskId && totalProductivityTime < 1 && !modalSessionOpen;

    if (
        (!viewCompletedTasks && task.completed) ||
        (switchTask && task.completed)
    ) {
        return null;
    }

    return (
        <TaskCard
            onClick={(e) => handleSelectTask(e, task)}
            completed={task.completed}
            selected={istaskId}
            switchTask={switchTask}
            as={motion.div}
            initial={'hidden'}
            variants={{
                hidden: {
                    opacity: 0,
                    x: -50,
                },
                show: {
                    opacity: 1,
                    x: 0,
                    transition: {
                        delay: (index || 0) * 0.1,
                    },
                },
                scale: {
                    x: 0,
                    scale: 1.01,
                    opacity: 1,
                },
            }}
            animate={variant}
            whileHover={{ scale: switchTask ? 1.02 : 1.01 }}
            key={task._id}
        >
            <TaskContainer>
                <CustomIcon
                    className={classnames(
                        'far',
                        { 'fa-check-circle': !task.completed && isHovered },
                        { 'fa-circle': !task.completed && !isHovered },
                        { 'fa-check-circle': task.completed && !isHovered },
                        { 'fa-circle': task.completed && isHovered }
                    )}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    onClick={() => handleMarkComplete()}
                />
                <TaskTitle completed={task.completed}>{task.name}</TaskTitle>
            </TaskContainer>
            {istaskId && !switchTask && (
                <IconsContainer>
                    <ActionIcon
                        type={'edit'}
                        onClick={() => handleUpdate('task', task.name)}
                        hoverColor={task.completed ? 'black' : undefined}
                    />
                    {!task.completed && (
                        <>
                            <HourGlassArrow render={renderHourglassArrow} />
                            <ActiveHourglassContainer animate={controls}>
                                <ActionIcon
                                    type={'hourglass'}
                                    onClick={() => setModalSessionOpen(true)}
                                />
                            </ActiveHourglassContainer>
                        </>
                    )}
                </IconsContainer>
            )}
        </TaskCard>
    );
}

export default memo(Task);

const TaskCard = styled.div<{
    completed: boolean;
    selected: boolean;
    switchTask?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(p) =>
        p.selected && p.completed
            ? p.theme.colors.grey200
            : !p.selected && p.completed
            ? 'white'
            : p.selected && !p.completed && !p.switchTask
            ? p.theme.colors.gold
            : !p.selected && !p.completed
            ? p.theme.colors.grey50
            : p.selected && p.switchTask
            ? p.theme.colors.grey300
            : null};
    padding: 5px 15px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    z-index: ${(p) => p.switchTask && 1000};

    &:hover {
        cursor: pointer;
        border: ${(p) => p.switchTask && `2px solid ${p.theme.colors.gold}`};
    }

    .bg-blue {
        background-color: ${(p) => p.theme.colors.darkBlue};
    }
`;

const TaskContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CustomIcon = styled.i`
    margin-right: 5px;
`;

const TaskTitle = styled.div<{ completed: boolean }>`
    font-size: ${(p) => p.theme.fontSizes.md};
    text-decoration: ${(p) => (p.completed ? 'line-through' : null)};
`;

const IconsContainer = styled.div`
    display: flex;
    margin-top: 5px;
    position: relative;
`;

const ActiveHourglassContainer = styled(motion.div)``;

const variants = {
    hidden: {
        opacity: 0,
        x: -50,
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            // duration: 0.4
        },
    },
    scale: {
        x: 0,
        scale: 1.05,
        opacity: 1,
    },
};

const zoom = {
    hidden: {
        opacity: 1,
        x: 50,
    },
};
