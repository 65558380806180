import { atom } from 'recoil';

export const addingSubtaskState = atom({
    key: 'addingSubtask',
    default: false
});

export const addingNoteState = atom({
    key: 'addingNote',
    default: false
});