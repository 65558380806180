import { withAuthenticationRequired } from '@auth0/auth0-react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { QUERIES } from '../../contants';
import Projects from '../Projects';
import Tasks from '../Tasks';
import { validTaskState } from '../Tasks/atoms';
import TaskDetails from '../TaskDetails';
import { addingNoteState, addingSubtaskState } from '../TaskDetails/atoms';
import ModalSetGoal from '../GoalsModal';
import ModalUpdate from '../ModalUpdate';
import ModalSession from '../ModalSession';
import { modalSessionState } from '../ModalSession/atoms';
import Loading from '../Loading';
import { userDataState } from '../../App';
import IntroModal, { introModalState } from '../IntroModal';

export const Todos = () => {
    const userData = useRecoilValue(userDataState);
    const validTask = useRecoilValue(validTaskState);
    const addingSubtask = useRecoilValue(addingSubtaskState);
    const addingNote = useRecoilValue(addingNoteState);
    const modalSessionOpen = useRecoilValue(modalSessionState);
    const setIntroModalOpen = useSetRecoilState(introModalState);

    if (userData._id && !userData.weeklyProductivityGoal)  {
        setIntroModalOpen(true);
    }

    return (
        <Container
            validTask={validTask}
            addingSubtask={addingSubtask}
            addingNote={addingNote}
        >
            <Projects />
            <TasksContainer validTask={validTask}>
                <Tasks />
            </TasksContainer>
            {validTask && (
                <DetailsContainer validTask={validTask}>
                    <TaskDetails />
                </DetailsContainer>
            )}
            <IntroModal />
            <ModalSetGoal />
            <ModalUpdate />
            <AnimatePresence
                initial={false}
                mode={'wait'}
                onExitComplete={() => null}
            >
                {modalSessionOpen && <ModalSession />}
            </AnimatePresence>
        </Container>
    );
};

const Container = styled.div<{
    validTask: boolean;
    addingSubtask: boolean;
    addingNote: boolean;
}>`
    --side-panes-width: max(1fr, 293px);
    display: grid;
    grid-template-columns: 2fr 8fr ${(p) =>
            p.addingSubtask || p.addingNote ? '3fr' : '2fr'};
    grid-template-areas: ${(p) =>
        p.validTask ? `'projects tasks details'` : `'projects tasks tasks'`};
    flex: 1;

    @media ${QUERIES.laptopAndDown} {
        grid-template-areas: ${(p) =>
            p.validTask
                ? `'projects projects projects'
            'tasks tasks details'`
                : `'projects projects projects'
            'tasks tasks tasks'`};
        grid-template-rows: auto 2fr;
    }

    @media ${QUERIES.tabletAndDown} {
        grid-template-areas: ${(p) =>
            p.validTask
                ? `'projects projects projects'
            'tasks tasks tasks'
            'details details details'`
                : `'projects projects projects'
            'tasks tasks tasks'`};
    }
`;

const TasksContainer = styled.div<{ validTask: boolean }>`
    grid-area: tasks;
`;

const DetailsContainer = styled.div<{ validTask: boolean }>`
    @keyframes slide {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }
    grid-area: details;
    animation: slide 300ms;
`;

export default withAuthenticationRequired(Todos, {
    onRedirecting: () => <Loading />,
});
