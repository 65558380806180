import { useEffect, useState } from 'react';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useSound from 'use-sound';

import { IReminderTask } from '../../globals/interfaces';
import useWorkTimer, { TimerType } from '../../hooks/useWorkTimer';
import { projectIdState, reminderTasksSelector } from '../Projects/atoms';
import _ from 'lodash';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { taskIdState } from '../Tasks/atoms';
import { Button } from 'reactstrap';
const reminder = require('../../assets/audio/reminder.mp3');

export default function Reminders() {
    const { dateTime } = useWorkTimer(TimerType.SECONDS);
    const reminderTasks = useRecoilValue(reminderTasksSelector);
    const setProjectId = useSetRecoilState(projectIdState);
    const setTaskId = useSetRecoilState(taskIdState);
    const [renderArray, setRenderArray] = useState<Array<IReminderTask>>([]);
    const [index, setIndex] = useState(0);
    const [playReminder] = useSound(reminder, {
        volume: 0.25,
    });

    useEffect(() => {
        setIndex(0);
    }, [reminderTasks]);

    useEffect(() => {
        if (!reminderTasks.length || index > reminderTasks.length - 1) {
            return;
        }
        const nextReminderTask = reminderTasks[index];
        if (
            nextReminderTask &&
            moment(nextReminderTask.reminderDate).format('MM/DD/YYYY HH:mm') ===
                moment(dateTime).format('MM/DD/YYYY HH:mm')
        ) {
            setRenderArray((prev) => {
                const copy = _.cloneDeep(prev);
                copy.unshift(nextReminderTask);
                return copy;
            });
            playReminder();
            setIndex((index) => index + 1);
        }
    }, [dateTime, reminderTasks, index]);

    const removeReminder = (reminderId: string) => {
        setRenderArray((prev) => {
            const filtered = prev.filter((task) => task._id !== reminderId);
            return filtered;
        });
    };

    const handleClickReminder = (e: any, reminderTask: IReminderTask) => {
        if (e.currentTarget === e.target) {
            setProjectId(reminderTask.projectId);
            setTimeout(() => {
                setTaskId(reminderTask._id);
            }, 500);
        }
        removeReminder(reminderTask._id);
    };

    const test = () => {
        setRenderArray((prev) => {
            const copy = _.cloneDeep(prev);
            copy.push({
                _id: Math.floor(Math.random() * 100).toString(),
                completed: false,
                name: 'test',
                projectId: 'TEST',
                notes: [],
                subtasks: [],
                attachments: [],
            });
            return copy;
        });
    };

    return (
        <AnimatePresence>
            <Container>
                {renderArray.map((task) => (
                    <Reminder
                        initial={{
                            opacity: 0,
                            y: -100,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                        exit={{
                            opacity: 0,
                            y: -100,
                        }}
                        whileHover={{ scale: 1.01 }}
                        onClick={(e) => handleClickReminder(e, task)}
                        key={task._id}
                    >
                        <div>
                            <i className={'fas fa-bell mr-3'} />
                            {task.name}
                        </div>
                        <CloseIcon
                            className={'fas fa-times'}
                            onClick={() => removeReminder(task._id)}
                        />
                    </Reminder>
                ))}
            </Container>
        </AnimatePresence>
    );
}

const Container = styled(motion.div)`
    position: absolute;
    bottom: 10px;
    max-height: 100px;
    overflow-y: hidden;
    padding-left: 5px;
`;

const Reminder = styled(motion.div)`
    background-color: ${(p) => p.theme.colors.greyAlt};
    border: 1px solid ${(p) => p.theme.colors.gold};
    height: 35px;
    width: 500px;
    margin-right: 40px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const CloseIcon = styled.i`
    &:hover {
        color: red;
    }
`;
