import { config } from '../authConfig';
import { useAuth0 } from "@auth0/auth0-react";
import { asyncHandler } from '../utils/index';
import axios from 'axios';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

interface ApiReponse {
    status: number,
    data: object,
    successful: boolean
} 

export default function useApi() {
    const { audience } = config;
    const { getAccessTokenSilently } = useAuth0();

    const api = asyncHandler(async(method: string, endpoint: string, data: object): Promise<object> => {
        const token = await getAccessTokenSilently({ audience });
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        let response: ApiReponse;

        if (['get', 'delete'].includes(method)) {
            // @ts-ignore
            response = await axios[method](`${REACT_APP_BASE_API_URL}${endpoint}`, config);
        } else {
            // @ts-ignore
            response = await axios[method](`${REACT_APP_BASE_API_URL}${endpoint}`, data, config);
        } 

        if ([200, 201, 204].includes(response.status)) {
            response.successful = true;
        } else {
            response.successful = false;
        }

        return response;
    });

    return {
        api
    };

}