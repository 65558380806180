import moment from 'moment';
import { useEffect, useState } from 'react';
import { IProductivityRecord } from '../globals/interfaces';

export default function useStreaks (productivityRecords: Array<IProductivityRecord>) {
    
    const [checkInStreak, setCheckInStreak] = useState(1);
    const [productivityStreak, setProductivityStreak] = useState(0);

    useEffect(() => {
        let _productivityStreak = 0, 
            _runProductivityStreak = true,
            _checkInStreak = 1,
            currentDate = moment().format('YYYY-MM-DD');

        for (let i = productivityRecords.length - 2; i > -1; i--) {
            const { createdAt, productivityGoal, productivityAchieved } = productivityRecords[i];
            const previousRecordDate = moment(createdAt).format('YYYY-MM-DD');
            // console.log(`previousRecordDate = ${previousRecordDate}; currentDate = ${currentDate}`);
            // console.log(moment(currentDate).diff(previousRecordDate, 'day'));
            if (moment(currentDate).diff(previousRecordDate, 'day') > 1) {
                break;
            }
            currentDate = previousRecordDate;
            _checkInStreak++;

            if (_runProductivityStreak && (productivityAchieved >= productivityGoal)) {
                _productivityStreak++;
            } else {
                _runProductivityStreak = false;
            }
        }

        setCheckInStreak(_checkInStreak);
        setProductivityStreak(_productivityStreak);
    }, [productivityRecords]);

    return  {
        checkInStreak,
        productivityStreak
    }
}