import { atom, selector } from "recoil";
import { isExpressionWithTypeArguments } from "typescript";
import { GoalType } from "../../globals/interfaces";
import { guardRecoilDefaultValue } from "../../utils";

export const modalSetGoalsState = atom({
    key: 'modalSetGoals',
    default: false
});

export const goalTypeState = atom<GoalType>({
    key: 'goalType',
    default: null
});

export const enforceSetGoalState = atom({
    key: 'enforceSetGoal',
    default: false
});

// @ts-ignore
export const handleSetGoalSelector = selector<GoalType>({
    key: 'handleSetGoal',
    get: () => {
        throw new Error('Not implemented');
    },
    set: ({set}, newValue) => {
        set(goalTypeState, newValue);
        set(modalSetGoalsState, true);
    }
});

export const goalsQueueState = atom<Array<GoalType>>({
    key: 'goalQueue',
    default: []
});

// setGoalTypeToday(goalTypeToday);
// setModalSetGoalsOpen(true);
