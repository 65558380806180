import React from 'react';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
    content: number;
}

export default function Badge ({
    children,
    content
}: Props) {
    return (
        <Container>
            {children}
            <Count>{content}</Count>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
`;

const Count = styled.div`
    position: absolute;
    top: -15px;
    right: -23px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: ${p => p.theme.colors.gold};
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
`;