import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Form,
} from 'reactstrap';

import {
    modalUpdateState,
    updateTypeState,
    updateValueState,
} from './updateAtoms';
import useUpdate from '../../hooks/useUpdate';

export default function ModalUpdate() {
    const [modalUpdate, setModalUpdate] = useRecoilState(modalUpdateState);
    const updateType = useRecoilValue(updateTypeState);
    const [updateValue, setUpdateValue] = useRecoilState(updateValueState);
    const { updateItem } = useUpdate();
    const enableSubmit = updateValue && updateValue.length > 0;

    const toggle = () => setModalUpdate((prev) => !prev);

    return (
        <Modal isOpen={modalUpdate} toggle={toggle}>
            <ModalHeader>Update {updateType}</ModalHeader>
            <ModalBody>
                <Form onSubmit={(e: any) => updateItem(e, 'update')}>
                    <Input
                        type="text"
                        value={updateValue}
                        onChange={(e) => setUpdateValue(e.target.value)}
                        autoFocus
                        // @ts-ignore
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className={`${enableSubmit && 'btn-submit'}`}
                    disabled={!enableSubmit}
                    onClick={(e: any) => updateItem(e, 'update')}
                >
                    Submit
                </Button>
                <Button
                    color="danger"
                    onClick={(e: any) => updateItem(e, 'delete')}
                >
                    Delete
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

const Container = styled.div`
    width: 500px;
    height: 360px;
    border-radius: 15px;
    background-color: white;
    position: fixed;
    padding: 10px;
`;
