import { DefaultValue } from 'recoil';
import { IReadAttachment } from '../globals/interfaces';

import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { Console } from 'console';
momentDurationFormatSetup(moment);


export const asyncHandler = (cb: any) => {
    return async (...args: any) => {
        try {
            return await cb(...args);
        } catch (error) {
            let status, name, message;
            // @ts-ignore
            if (error.response && error.response.data) {
                // @ts-ignore
                status = error.response.status;
                // @ts-ignore
                name = error.response.data.name && error.response.data.name;
                // @ts-ignore
                message = error.response.data.message && error.response.data.message;
            }

            console.log(error);

            // const errorMessage = `Sorry - something went wrong!\n${status}: ${name}. ${message}`;
            // NotificationManager.error(`${errorMessage}`, null, 10000);
            return {};
        }
    };
};

export const timeFormatter = (seconds: number, short?: boolean) => {
    // @ts-ignore
    const duration = moment.duration(seconds, 'seconds');
    if (short) {
        if (seconds <= 0 || seconds === Infinity || isNaN(seconds)) {
            return '00:00';
        }
        return moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm")
    }
    if (seconds >= 3600) {
        // @ts-ignore
        return duration.format('hh:mm:ss');
    } else if (seconds <= 0) {
        return '00:00:00';
    } else {
        // @ts-ignore
        return `00:${duration.format('hh:mm:ss')}`;
    }
};

export const formatEmail = (email: string) => {
    if (email) {
        const parts = email.split('@');
        if (parts.length > 0) {
            return parts[0];
        } else {
            return '';
        }
    } else {
        return '';
    }
};

export const formatPercent = (float: number) => {
    return `${float.toFixed(0)}%`;
};

export const guardRecoilDefaultValue = (
    candidate: any
): candidate is DefaultValue => {
    if (candidate instanceof DefaultValue) return true;
    return false;
};

export const resolveAttachments = (attachments: Array<IReadAttachment>) => {
    const arrayBufferToBase64 = (buffer: ArrayBufferLike) => {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return binary;
    }

    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        if (contentType === 'text/plain') {
            const blob = URL.createObjectURL(new Blob([b64Data] , {type:'text/plain'}));
            return blob;
        } else {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];
          
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
            
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
            
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
          
            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }
    }
    
    const array = [];

    if (attachments) {
        for (let i = 0; i < attachments.length; i++) {

            const { _id, data, contentType, name } = attachments[i];

            if (data) {
                const base64 = arrayBufferToBase64(data.data);
                const blob = b64toBlob(contentType === 'text/plain' ? data.data : base64, contentType);
    
                array.push({
                    _id,
                    name,
                    contentType,
                    blob
                });
            }

        }
    }

    return array;
}

export const properCase = (text: string) => {
    if (!text) return '';
    return `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}`;
}