import { atom, selector } from "recoil";
import moment from 'moment';
import _ from 'lodash';

import { IProject, IReminderTask, ITask } from "../../globals/interfaces";
import { defaultProject } from "../../globals/defaults";
import { guardRecoilDefaultValue } from "../../utils";

export const projectsState = atom<Array<IProject>>({
    key: 'projects',
    default: []
});

export const updateProjectsSelector = selector<IProject>({
    key: 'updateProjectsStateById',
    // @ts-ignore
    get: () => {},
    set: ({get, set}, project) => {
        if (guardRecoilDefaultValue(project)) return; 
        const projects = get(projectsState);
        const copy = _.cloneDeep(projects);
        const updateIndex = copy.findIndex(p => p._id === project._id);
        copy[updateIndex] = project;
        set(projectsState, copy);
    }
});

export const deleteProjectSelector = selector<string>({
    key: 'deleteProjectsStateById',
    // @ts-ignore
    get: () => {},
    set: ({get, set}, projectId) => {
        const projects = get(projectsState);
        const filtered = projects.filter(p => p._id !== projectId);
        set(projectsState, filtered);
    }
});


export const projectIdState = atom<string>({
    key: 'projectId',
    default: ''
});

export const projectsSelector = selector<Array<IProject>>({
    key: 'projectsSelector',
    get: ({get}) => {
        const projects = get(projectsState);
        const dueToday: IProject = {
            _id: 'dueToday',
            completed: false,
            name: 'Due Today',
            tasks: Array<ITask>()
        };
    
        for (let i = 0; i < projects.length; i++) {
            const { _id, tasks=[] } = projects[i];
            if (_id !== dueToday._id) {
                for (let j = 0; j < tasks.length; j++) {
                    const currentTask = tasks[j];
                    if (currentTask.dueDate && moment.utc(currentTask.dueDate).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')) {
                        dueToday.tasks.push(currentTask);
                    }
                }
            }
        }

        const output = [dueToday, ...projects];
        return output;
    }
});

export const projectSelector = selector<IProject>({
    key: 'selectedProject',
    get: ({ get }) => {
        const projects = get(projectsSelector);
        const projectId = get(projectIdState);
        return projects.find(p => p._id === projectId) || defaultProject;
    }
});

export const reminderTasksSelector = selector<Array<IReminderTask>>({
    key: 'reminderTasks',
    get: ({get}) => {
        const projects = get(projectsSelector);
        const _reminderTasks: Array<IReminderTask> = [];

        for (let i = 0; i < projects.length; i++) {
            const currentProject = projects[i];
            const tasks = _.get(currentProject, 'tasks', []);

            for (let j = 0; j < tasks.length; j++) {
                const currentTask = tasks[j];
                const { reminderDate=null, completed } = tasks[j];
                if (
                    !completed && 
                    moment(reminderDate).isValid() && 
                    moment().format('MM/DD/YYYY') === moment(reminderDate).format('MM/DD/YYYY') && 
                    moment(reminderDate).isSameOrAfter(moment())
                ) {
                    /* 
                        If the tasks is changed to add a project, this will not exist in the task 
                        once removed:
                        // currentTask.project = currentProject;
                    */
                    const reminderTask: IReminderTask = _.cloneDeep(currentTask);
                    reminderTask.projectId = currentProject._id;
                    _reminderTasks.push(reminderTask);
                }
            }
        }

        console.log('FINISHED');

        return _reminderTasks;
        //return _reminderTasks.sort((a, b) => +moment(b.reminderDate) - +moment(a.reminderDate));
    }
});
