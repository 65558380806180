
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IMap } from '../../globals/interfaces';

interface Props {
    type: 'edit' | 'hourglass';
    onClick: (props?: any) => any;
    color?: string;
    hoverColor?: string;
    size?: 'lg' | 'md' | 'sm';
    className?: string
}

const name: IMap<string> = {
    edit: 'fas fa-edit',
    hourglass: 'fas fa-hourglass-half'
}

export default function ActionIcon ({
    type,
    onClick,
    color,
    hoverColor,
    size,
    className
}: Props) {
    return (
        <Icon 
            as={motion.i}
            whileHover={{ scale: 1.1 }}
            type={type}
            className={`${name[type]} ${className}`}
            onClick={onClick}
            color={color}
            hoverColor={hoverColor} 
            size={size}
        />
    );
}

const Icon = styled.i<Props>`
    color: ${p => p.color ? p.color : 'black'};
    font-size: ${p => p.size ? p.theme.fontSizes[p.size] : p.theme.fontSizes.md};
    padding: 0px 5px;
    &:hover {
        color: ${p => p.hoverColor ? p.color : 'white'};
        cursor: pointer;
    }
`;