import React from 'react';
import styled from "styled-components";
import { motion } from 'framer-motion';

interface Props {
    useKey: string;
    children: React.ReactNode;
}

export default function SlideUpContainer ({
    useKey,
    children
}: Props) {
    return (
        <Container
            as={motion.div}
            variants={variants}
            key={useKey}
            initial={'closed'}
            animate={'open'}
        >
            {children}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};