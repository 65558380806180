import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { ISaveAttachment, IReadAttachment, IResolvedAttachment, ITask } from '../../globals/interfaces';
import { resolveAttachments } from '../../utils/index';
import SlideUpContainer from '../SlideUpContainer';
import ListItem from '../ListItem';
import { Icon } from '../Icon';
import { downloadFile } from './utils';

interface Props {
    selectedTask: ITask;
    attachments: Array<IReadAttachment>,
    saveAttachments: (attachments: Array<ISaveAttachment>) => Promise<boolean>;
    deleteAttachment: (_id: string) => Promise<void>;
}

export default function Attachments ({
    selectedTask,
    attachments,
    saveAttachments,
    deleteAttachment
}: Props) {

    const [resolvedAttachments, setResolvedAttachments] = useState<Array<IResolvedAttachment>>([]);
    const [attachmentsKey, setAttachmentsKey] = useState(0);
    const [hoverKey, setHoverKey] = useState('');

    useEffect(() => {
        const resolvedAttachments = resolveAttachments(attachments);
        setResolvedAttachments(resolvedAttachments);
    }, [attachments]);

    const convertToBase64 = (file: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // @ts-ignore
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    }

    const handleSaveAttachments = async (files: any) => {
        const array: Array<ISaveAttachment> = [];
        for (let i = 0; i < files.length; i++) {
            const { name, type } = files[i];
            const base64 = await convertToBase64(files[i]);
            array.push({
                name,
                type,
                base64
            })
        }
        const result = await saveAttachments(array);
        if (result) {
            setAttachmentsKey(key => key+1);
        }
    }

    return (
        <OuterContainer>
            <h5>Attachments</h5>
            <SlideUpContainer useKey={selectedTask._id}>
                {
                    resolvedAttachments.map((a, i) => 
                        <ListItem
                            key={`${selectedTask._id}-${i}`}
                            open={true}
                        >
                            <InnerContainer>
                                <FlexContainer
                                    onMouseOver={() => setHoverKey(a.name)}
                                    onMouseOut={() => setHoverKey('')}
                                >
                                    <AnimatePresence>
                                        {
                                            hoverKey === a.name ? (
                                                <DownloadIcon 
                                                    as={motion.i}
                                                    className={'fas fa-arrow-down'}
                                                    animate={{ scale: 1.05 }}
                                                    transition={{ type: "spring", bounce: 0.5 }}    
                                                />
                                            ) : (
                                                <DownloadIcon 
                                                    className={'fas fa-circle'} 
                                                />
                                            )
                                        }
                                    </AnimatePresence>
                                    <DownloadFile
                                        as={motion.div}
                                        whileHover={{ scale: 1.02 }}
                                    >
                                        {
                                            a.contentType === 'text/plain' ?
                                            <DownloadLink href={String(a.blob)} id="link" download={a.name}>{a.name}</DownloadLink> :
                                            <div onClick={() => downloadFile(a.blob, a.name)}>
                                                {a.name}
                                            </div>
                                        }
                                    </DownloadFile>
                                </FlexContainer>
                                <DeleteIcon 
                                    className={'fas fa-times'} 
                                    onClick={() => deleteAttachment(a._id)}
                                />
                            </InnerContainer>
                        </ListItem>
                    )
                }
            </SlideUpContainer>
            <UploadContainer>
                <input 
                    type={'file'} 
                    onChange={(e) => handleSaveAttachments(e.target.files)} 
                    key={attachmentsKey} 
                />
            </UploadContainer>
        </OuterContainer>
    );
}

const OuterContainer = styled.div`

`;

const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const UploadContainer = styled.div`
    width: 100px;
    margin-top: 10px;
    border: 1px solid green;
`;

const DownloadFile = styled.div`
    display: flex;
`;

const DownloadLink = styled.a`
    text-decoration: none;
    color: white !important;
    &:hover {
        cursor: pointer;
    }
`;

const DownloadIcon = styled(Icon)`
    padding-left: 2px;
    padding-right: 6px;
    font-size: 16px;
`;

const DeleteIcon = styled(Icon)`
    font-size: 16px;
    &:hover {
        color: red;
    }
`;