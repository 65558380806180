import axios from 'axios';

import { config as authConfig } from '../authConfig';
const { audience } = authConfig;
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const apiClient = axios.create({
    baseURL: REACT_APP_BASE_API_URL
});

interface IAudience {
    audience: string;
}

export const addAccessTokenInterceptor = (
    getAccessTokenSilently: (audience: IAudience) => Promise<string>
) => {
    apiClient.interceptors.request.use(async (config) => {
        const token = await getAccessTokenSilently({ audience });
        if (config && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
};

export default apiClient;