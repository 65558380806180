import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

// import NavBar from './NavBar';
import StatusBarGraph from '../StatusBarGraph';
import useWorkTimer from '../../hooks/useWorkTimer';
import usePermissions from '../../hooks/usePermissions';
import { timeFormatter } from '../../utils';
import useStreaks from '../../hooks/useStreaks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentProductivityRecordState } from '../Todos/atoms';

import { goalTypeState } from '../GoalsModal/atoms';
import Badge from '../Badge';
import Tooltip from '../Tooltip';
import { motion } from 'framer-motion';
import Avatar from '../Avatar';
import { userDataState } from '../../App';
import Reminders from '../Reminders';
import apiClient from '../../api';
import _ from 'lodash';
import { introModalState } from '../IntroModal';
const logo = require('../../assets/img/icon-gold.svg').default;

interface IUpdateStreaksData {
    userId: string;
    longestCheckInStreak?: number;
    longestProductivityStreak?: number;
}

export default function StatusBar() {
    const [userData, setUserData] = useRecoilState(userDataState);
    const currentProductivityRecord = useRecoilValue(
        currentProductivityRecordState
    );

    const { weeklyProductivityGoal, productivityRecords } = userData;
    const { productivityGoal, productivityAchieved } =
        currentProductivityRecord;
    const setGoalType = useSetRecoilState(goalTypeState);

    const { permission, handleRequestPermission } = usePermissions();

    const { checkInStreak, productivityStreak } =
        useStreaks(productivityRecords);
    const [dropdown, setDropDown] = useState(false);
    const setIntroModalOpen = useSetRecoilState(introModalState);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleDropDown = () => setDropDown((prev) => !prev);

    useEffect(() => {
        const updateStreaks = async (data: IUpdateStreaksData) => {
            const res = await apiClient.put('/user/stats/streaks', data);
            if (res.status === 200) {
                setUserData((prev) => {
                    const copy = _.cloneDeep(prev);
                    if (data.longestCheckInStreak) {
                        copy.stats.longestCheckInStreak =
                            data.longestCheckInStreak;
                    }
                    if (data.longestProductivityStreak) {
                        copy.stats.longestProductivityStreak =
                            data.longestProductivityStreak;
                    }
                    return copy;
                });
            }
        };

        if (!userData._id) return;

        let runUpdate = false;
        const { longestCheckInStreak, longestProductivityStreak } =
            userData.stats;
        const data: IUpdateStreaksData = {
            userId: userData._id,
            longestCheckInStreak,
            longestProductivityStreak,
        };

        if (checkInStreak > longestCheckInStreak) {
            data.longestCheckInStreak = checkInStreak;
            runUpdate = true;
        }
        if (productivityStreak > longestProductivityStreak) {
            data.longestProductivityStreak = productivityStreak;
            runUpdate = true;
        }
        if (runUpdate) {
            updateStreaks(data);
        }
    }, [userData._id, checkInStreak, productivityStreak]);

    return (
        <Container data-test="status-bar">
            <DataContainer>
                <StatusBarContainer>
                    <StatusBarGraph
                        productivityGoal={productivityGoal}
                        productivityAchieved={productivityAchieved}
                        backgroundColor={'#252839'}
                        textColor={'white'}
                        trailColor={'white'}
                    />
                </StatusBarContainer>
                <WorkTimeAvailable />
                <Dropdown
                    isOpen={dropdown}
                    toggle={toggleDropDown}
                    direction={'down'}
                >
                    <Tooltip
                        id={'clockIcon'}
                        content={'Goals (click to edit)'}
                    />
                    <Icon
                        className="fas fa-clock"
                        onClick={toggleDropDown}
                        id={'clockIcon'}
                        whileHover={{ scale: 1.2 }}
                    />
                    <DropdownMenu>
                        <DropdownItem header>
                            Current Productive Time:{' '}
                            {timeFormatter(productivityAchieved)}
                        </DropdownItem>
                        {/* @tsd-ignore */}
                        <DropdownItem
                            onClick={() => setGoalType('DAY')}
                            className={
                                'd-flex justify-content-between align-items-center'
                            }
                        >
                            Productivity Goal: {timeFormatter(productivityGoal)}
                            <SmallEditIcon className="fas fa-edit text-dark" />
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => setGoalType('WEEK')}
                            className={
                                'd-flex justify-content-between align-items-center'
                            }
                        >
                            Weekly Goal: {timeFormatter(weeklyProductivityGoal)}
                            <SmallEditIcon className="fas fa-edit text-dark" />
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Tooltip id={'checkInStreakTip'} content={'Check In Streak'} />
                <Badge content={checkInStreak}>
                    <Icon
                        className="fas fa-calendar-alt text-white"
                        id={'checkInStreakTip'}
                    />
                </Badge>
                <Tooltip
                    id={'productivityStreakTip'}
                    content={'Productivity Streak'}
                />
                <Badge content={productivityStreak}>
                    <Icon
                        className="fas fa-bolt text-white"
                        id={'productivityStreakTip'}
                        data-tip={'Productivity Streak'}
                    />
                </Badge>
                <Tooltip id={'helpTip'} content={'Help'} />
                <Icon
                    className="fa fa-question text-white"
                    whileHover={{ scale: 1.2 }}
                    onClick={() => setIntroModalOpen(true)}
                    id={'helpTip'}
                />
            </DataContainer>
            <TitleContainer>
                <NavLink to="/todos">
                    <Title>Todo Plus</Title>
                    <TitleLogo src={logo} />
                </NavLink>
            </TitleContainer>
            <NavBarContainer>
                <Reminders />
                {!permission && (
                    <button
                        color={'warning'}
                        data-tip={
                            'Please enable notifications so you can be alerted when you complete a productivity session.'
                        }
                        onClick={() => handleRequestPermission()}
                    >
                        <i className="fas fa-exclamation-triangle" />
                        Enable Notifications
                    </button>
                )}
                <Avatar />
            </NavBarContainer>
        </Container>
    );
}

const WorkTimeAvailable = () => {
    const { workTimeAvailable } = useWorkTimer();

    return <TimeAvailableContainer>{workTimeAvailable}</TimeAvailableContainer>;
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    height: 109.91px;
    background-color: #252839;
`;

const DataContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    & > * {
        margin-right: 30px;
    }
`;

const TimeAvailableContainer = styled.div`
    width: 123px;
    font-size: ${(p) => p.theme.fontSizes.xxl};
`;

const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h1`
    color: white;
    font-size: 36px;
    font-family: 'Orbitron', sans-serif;

    &:hover {
        color: #f2b632;
        cursor: pointer;
    }

    @media (max-width: 1500px) {
        display: none;
    }
`;

const TitleLogo = styled.img`
    width: 150px;
    height: auto;
    top: -20px;
    left: -20px;
    display: none;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 1500px) {
        display: block;
    }

    @media (max-width: 800px) {
        display: none;
    }
`;

const StatusBarContainer = styled.div`
    height: 110px;
    width: 110px;
`;

const NavBarContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
`;

const Icon = styled(motion.i)`
    color: white;
    font-size: ${(p) => p.theme.fontSizes.xl};
    &:hover {
        cursor: pointer;
    }
`;

const SmallEditIcon = styled.i`
    margin-left: 12px;
    font-size: 12px;
`;
