import { atom } from "recoil";

export const modalSessionState = atom({
    key: 'modalSession',
    default: false
});

export const switchTaskState = atom({
    key: 'switchTask',
    default: false
});