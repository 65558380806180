import { atom, selector } from "recoil";
import { UpdateType } from "../../globals/interfaces";
import { guardRecoilDefaultValue } from "../../utils";

export const modalUpdateState = atom<boolean>({
    key: 'modalUpdate',
    default: false
});

export const updateTypeState = atom<UpdateType>({
    key: 'updateType',
    default: 'project'
});

export const updateValueState = atom<string>({
    key: 'updateValue',
    default: ''
}); 

export const subtaskNoteIdState = atom<string | null>({
    key: 'subtaskNoteId',
    default: null
});

interface HandleUpdateSelectorType {
    updateType: UpdateType;
    subtaskNoteId?: string;
}

export const handleUpdateSelector = selector<HandleUpdateSelectorType>({
    key: 'handleUpdate',
    // @ts-ignore
    get: () => null,
    set: ({get, set}, updateConfig) => {
        if (guardRecoilDefaultValue(updateConfig)) return;
        const { updateType, subtaskNoteId } = updateConfig;
        set(updateTypeState, updateType);
        if (subtaskNoteId) {
            set(subtaskNoteIdState, subtaskNoteId);
        }
        set(modalUpdateState, true);
    }
});