import { useState } from 'react';
import _ from 'lodash';
import * as moment from "moment";
import useSound from 'use-sound';

import useApi from '../../hooks/useApi';
import { IProductivityRecord, ISessionTask, IUserData } from '../../globals/interfaces';
import momentDurationFormatSetup from "moment-duration-format";
import { defaultProductivityRecord } from '../../globals/defaults';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { goalsQueueState, goalTypeState, modalSetGoalsState } from './atoms';
import { currentProductivityRecordState } from '../Todos/atoms';
import { userDataState } from '../../App';

momentDurationFormatSetup(moment);

const success = require('../../assets/audio/success-3.mp3');

export default function useGoals () {
    // Goal setting:
    const { api } = useApi();
    const [userData, setUserData] = useRecoilState(userDataState);
    const goalType = useRecoilValue(goalTypeState);
    const [currentProductivityRecord, setCurrentProductivityRecord] = useRecoilState(currentProductivityRecordState);
    // const [modalSessionOpen, setModalSessionOpen] = useState<boolean>(false);
    // const [switchTask, setSwitchTask] = useState<boolean>(false);
    const setGoalType = useSetRecoilState(goalTypeState);

    const [playSuccess] = useSound(success, {
        volume: 0.25
    });

    const switchSetGoal = (seconds: number, workDays?: number): void => {
        if (goalType === 'DAY') {
            setProductivityGoal(seconds);
        } else {
            if (seconds && workDays) {
                setWeeklyProductivityGoal(seconds, workDays);
            }
        }
        playSuccess();
    };

    const setWeeklyProductivityGoal = async(seconds: number, workDays: number): Promise<void> => {

        const data = {
            _id: userData._id,
            weeklyProductivityGoal: seconds,
            workDays
        };

        const response = await api('put', '/user/productivity-goal/week', { data });

        if (response.successful) {
            const copy = _.cloneDeep(userData);
            copy.weeklyProductivityGoal = seconds;
            copy.workDays = workDays;
            setUserData(copy);
        }  
    };

    const setProductivityGoal = async(seconds: number): Promise<void> => {
        const previousProductivityGoal = currentProductivityRecord.productivityGoal;
        const data = {
            productivityRecordId: _.get(currentProductivityRecord, '_id', null),
            productivityGoal: seconds,
            userId: userData._id,
            previousProductivityGoal
        };

        console.log(data);

        const response = await api('put', '/user/productivity-goal/day', { data });

        if (response.successful) {
            setCurrentProductivityRecord(response.data);
            // update user stat totalGoalTime
            setUserData(prev => {
                const copy = _.cloneDeep(prev);
                copy.stats.totalGoalTime -= previousProductivityGoal;
                copy.stats.totalGoalTime += response.data.productivityGoal; // seconds also works
                return copy;
            });
        }
    };

    return {
        switchSetGoal,
        setWeeklyProductivityGoal,
        setProductivityGoal
    }
    
}