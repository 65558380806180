import React, { useMemo, useState } from 'react';
import { Row, Col, FormGroup, Input, Label, Tooltip } from 'reactstrap';
import useSound from 'use-sound';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Table from '../Table';
import useLeaderboardsData from './useLeaderboardsData';
import useDataOptions from './useDataOptions';
import { IUserData } from '../../globals/interfaces';
import useSounds from '../../globals/useSounds';
import { LeaderboardOption } from './interfaces';

interface Props {
    userData: IUserData;
    updateAnonymousStatus: () => Promise<void>;
    activeTab: string;
}

export default function Leaderboards({
    userData,
    updateAnonymousStatus,
    activeTab,
}: Props) {
    const { dataMap } = useLeaderboardsData();
    const [selectedOption, setSelectedOption] = useState<LeaderboardOption>(
        'totalProductivityTime'
    );

    const [key, setKey] = useState(0);

    const { playClick } = useSounds();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleChangeStatus = async () => {
        await updateAnonymousStatus();
        setKey((key) => key + 1);
        playClick();
    };

    const isOfTypeLeaderboardOption = (
        option: string
    ): option is LeaderboardOption => {
        return [
            'totalProductivityTime',
            'overallProductivityScore',
            'highestProductivityTime',
            'highestProductivityScore',
            'avgDailyProductivityTime',
            'longestProductivityStreak',
            'longestCheckInStreak',
        ].includes(option);
    };

    const handleSelectOption = (option: string) => {
        if (isOfTypeLeaderboardOption(option)) {
            setSelectedOption(option);
        }
    };

    return (
        <Container
            as={motion.div}
            initial={{
                height: 0,
                opacity: 0,
            }}
            animate={{
                height: 'auto',
                opacity: 1,
            }}
            transition={{
                delay: 0.25,
                x: { duration: 1 },
                default: { ease: 'linear' },
            }}
            key={activeTab}
        >
            <Tooltip
                isOpen={tooltipOpen}
                toggle={() => setTooltipOpen((prev) => !prev)}
                target={'anonymousTooltip'}
            >
                You can hide your name from the Leaderboards to remain
                anonymous. Use the toggle to change settings.
            </Tooltip>
            <AnonymousContainer>
                <FormGroup switch>
                    <Label check className={'mr-2'}>
                        Anonymous
                    </Label>
                    <i
                        className={'fas fa-info-circle'}
                        id={'anonymousTooltip'}
                    ></i>
                    <Input
                        type="switch"
                        role="switch"
                        checked={userData.anonymous}
                        onClick={() => handleChangeStatus()}
                        className={'shadow-none'}
                    />
                </FormGroup>
            </AnonymousContainer>
            <Row>
                <Col md={12}>
                    <Input
                        type={'select'}
                        value={selectedOption}
                        onChange={(e) => handleSelectOption(e.target.value)}
                    >
                        {(
                            Object.keys(dataMap) as Array<keyof typeof dataMap>
                        ).map((key) => (
                            <option value={key}>{dataMap[key].name}</option>
                        ))}
                    </Input>
                    <Table
                        data={dataMap[selectedOption].data}
                        type={dataMap[selectedOption].type}
                        key={key}
                        userData={userData}
                    />
                </Col>
            </Row>
        </Container>
    );
}

const Container = styled.div`
    padding: 10px;
    overflow: hidden;
`;

const AnonymousContainer = styled.div`
    display: flex;
`;

const AnonymousTitle = styled.div`
    display: flex;
    padding-right: 10px;
`;
