import _ from 'lodash';
import { useState, useEffect } from 'react';

import { ITask, ISessionTask, ITimerState } from "../../globals/interfaces";

export default function useSessionTasks (
    selectedTask: ITask, 
    currentSeconds: number,
    productionSeconds: number,
    timerState: ITimerState
) {
    
    const [sessionTasks, setSessionTasks] = useState<Array<ISessionTask>>([]);

    useEffect(() => {
        const addTask = (task: ITask, currentSeconds: number) => {
            setSessionTasks((prev: Array<ISessionTask>) => {
                const tasksCopy = _.cloneDeep(prev);
                if (tasksCopy.length > 0) {
                    tasksCopy[tasksCopy.length - 1].secondsEnd = currentSeconds;
                }
                tasksCopy.push({
                    task,
                    secondsStart: currentSeconds,
                    secondsEnd: 0
                })
                console.log(tasksCopy);
                return tasksCopy;
            });
        }
        const sameTask = sessionTasks.length && sessionTasks[sessionTasks.length - 1].task._id === selectedTask._id;
        if (selectedTask._id !== '' && !sameTask) {
            addTask(selectedTask, currentSeconds);
        }
    }, [selectedTask._id]);

    useEffect(() => {
        if (
            timerState === 'START SESSION' || 
            timerState === 'SESSION COMPLETE'
        ) {
            setSessionTasks([{
                task: selectedTask,
                secondsStart: productionSeconds,
                secondsEnd: 0
            }]);
        }
    }, [timerState]);

    return sessionTasks;

}