import { selector } from 'recoil';
import _ from 'lodash';
import moment from 'moment';

import { IProductivityRecord, IReminderTask } from '../../globals/interfaces';
import { guardRecoilDefaultValue } from '../../utils';
import { defaultProductivityRecord } from '../../globals/defaults';
import { userDataState } from '../../App';
import { projectsState } from '../Projects/atoms';

export const currentProductivityRecordState = selector<IProductivityRecord>({
    key: 'currentProductivityRecord',
    get: ({get}) => {
        //return defaultProductivityRecord;
        const userData = get(userDataState);
        console.log(userData);
        if (userData && userData.productivityRecords.length > 0) {
            const { productivityRecords } = userData;
            return productivityRecords[productivityRecords.length - 1];    
        }
        return defaultProductivityRecord;
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) return; 
        const userData = get(userDataState);
        const copy = _.cloneDeep(userData);
        const { productivityRecords } = copy;
        productivityRecords[productivityRecords.length - 1] = newValue;
        set(userDataState, copy);
    }
});
