import styled from 'styled-components';

interface Props {
    render: boolean;
}

export default function HourGlassArrow({ render }: Props) {
    if (!render) return null;

    return (
        <Container>
            <Text>Click here:</Text>
            <Icon className={'fas fa-arrow-down pulse'} />
        </Container>
    );
}

const Container = styled.div`
    position: absolute;
    top: -55px;
    right: -25px;
    text-align: center;
    /* background-color: white;
    opacity: 0.8;
    border-radius: 5px; */
`;

const Text = styled.h6`
    color: white;
    text-shadow: 0 0 10px #000;
`;

const Icon = styled.i`
    font-size: 20px;
    color: white;
    text-shadow: 0 0 10px #000;
`;
