import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
} from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment';
import classnames from 'classnames';
import { Tooltip } from 'reactstrap';

import { currentProductivityRecordState } from '../Todos/atoms';
import { modalSetGoalsState, goalTypeState } from './atoms';
import useGoals from './useGoals';
import styled from 'styled-components';
import { timeFormatter } from '../../utils';
import { userDataState } from '../../App';
import Timer from './Timer';
import { introModalState } from '../IntroModal';

export default function ModalSetGoal() {
    const userData = useRecoilValue(userDataState);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [modalSetGoalsOpen, setModalSetGoalsOpen] =
        useRecoilState(modalSetGoalsState);
    const [goalType, setGoalType] = useRecoilState(goalTypeState);
    console.log(goalType);
    const goalTypeToday = goalType === 'DAY';

    const currentProductivityRecord = useRecoilValue(
        currentProductivityRecordState
    );
    const { productivityGoal } = currentProductivityRecord;

    const [hours, setHours] = useState<number | string>('');
    const [min, setMin] = useState<number | string>('');
    const [message, setMessage] = useState('');
    const [enableSetGoal, setEnableSetGoal] = useState(false);
    const [goalSeconds, setGoalSeconds] = useState(0);
    const [workDays, setWorkDays] = useState(0);

    const { switchSetGoal } = useGoals();
    const goalSuggestion = timeFormatter(
        userData.weeklyProductivityGoal / userData.workDays
    );

    const introModalOpen = useRecoilValue(introModalState);

    useEffect(() => {
        if (userData._id === '') {
            return;
        }
        if (!userData.weeklyProductivityGoal || !userData.workDays) {
            setGoalType('WEEK');
        } else if (
            !currentProductivityRecord ||
            !currentProductivityRecord.productivityGoal
        ) {
            setGoalType('DAY');
        } else {
            setGoalType(null);
        }
    }, [userData, currentProductivityRecord, setGoalType]);

    const existingGoalParser = (goal: number) => {
        const duration = moment.duration(goal, 'seconds');
        const string = duration.format('hh:mm');
        let _hours = 0,
            _min = 0;
        if (string.includes(':')) {
            const parts = duration.format('hh:mm').split(':');
            _hours = Number(parts[0]);
            _min = Number(parts[1]);
        } else {
            _min = Number(string);
        }

        return {
            hours: _hours > 0 ? _hours : '',
            min: _min > 0 ? _min : '',
        };
    };

    useEffect(() => {
        let hours: number | string = '',
            min: number | string = '';

        if (goalType === 'WEEK') {
            const { weeklyProductivityGoal } = userData;
            const existingGoal = existingGoalParser(weeklyProductivityGoal);
            hours = existingGoal.hours;
            min = existingGoal.min;
        } else {
            const existingGoal = existingGoalParser(productivityGoal);
            hours = existingGoal.hours;
            min = existingGoal.min;
        }

        setHours(hours);
        setMin(min);
    }, [modalSetGoalsOpen, goalType, userData, productivityGoal]);

    const checkNaNorZero = (value: any) => {
        if (parseInt(value) === 0 || isNaN(parseInt(value))) {
            return true;
        }
        return false;
    };

    const goalTimeToSeconds = (hours: any, min: any) => {
        const hoursDelta = isNaN(parseInt(hours)) ? 0 : parseInt(hours);

        const minuteDelta = isNaN(parseInt(min)) ? 0 : parseInt(min);

        const hoursToSeconds = hoursDelta * 60 * 60;
        const minutesToSeconds = minuteDelta * 60;
        const seconds = hoursToSeconds + minutesToSeconds;

        return seconds;
    };

    useEffect(() => {
        const validateGoalTime = () => {
            if (goalTypeToday) {
                const eod = moment().hour(24).minute(0).second(0);
                const nowPlusGoalTime = moment().add({
                    hours: Number(hours),
                    minutes: Number(min),
                });
                const overTimeGoal = nowPlusGoalTime.isAfter(eod);
                const noGoalTime =
                    checkNaNorZero(hours) && checkNaNorZero(min) ? true : false;

                if (overTimeGoal) {
                    return 1;
                } else if (noGoalTime) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                const seconds = goalTimeToSeconds(hours, min);
                if (seconds > 201600) {
                    return 1;
                } else if (seconds < 1) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };

        const validationResult = validateGoalTime();

        if (validationResult === 0) {
            setEnableSetGoal(true);
            setMessage('');
            const seconds = goalTimeToSeconds(hours, min);
            setGoalSeconds(seconds);
        } else {
            setEnableSetGoal(false);
            if (validationResult === 1) {
                if (goalTypeToday) {
                    setMessage('Goal time cannot exceed time left in the day.');
                } else {
                    setMessage(
                        'Current maximum weekly goal cannot be more than 56 hours.'
                    );
                }
            } else {
                setMessage('Goal time cannot be 0.');
            }
        }

        if (!goalTypeToday && (workDays < 1 || workDays > 7)) {
            setEnableSetGoal(false);
            setMessage(
                (message) => `${message}\nWork Days must be between 1 and 7.`
            );
        }
    }, [hours, min, goalType, workDays]);

    useEffect(() => {
        setWorkDays(userData.workDays);
    }, [userData.workDays]);

    const enforceSetGoal =
        !userData.weeklyProductivityGoal ||
        !userData.workDays ||
        !currentProductivityRecord.productivityGoal;

    const handleToggle = () => !enforceSetGoal && setGoalType(null);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <CustomModal isOpen={goalType !== null && !introModalOpen} toggle={handleToggle}>
            <Tooltip
                isOpen={tooltipOpen}
                target="productivityGoalTooltip"
                toggle={toggle}
            >
                {`This is how much producitive time you want to achieve for ${
                    goalTypeToday ? 'today.' : 'the week.'
                }\nYou can change your goals later.`}
            </Tooltip>
            <ModalHeader>
                <FlexContainer>
                    <div>
                        Set Productivity Goal for{' '}
                        {goalTypeToday ? 'Today' : 'the Week'}
                    </div>
                    <i
                        className={'fas fa-info-circle'}
                        id={'productivityGoalTooltip'}
                    />
                    {userData.weeklyProductivityGoal < 1 && (
                        <a
                            href={'https://www.youtube.com/watch?v=yG_Pq3Zoidk'}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            <Button
                                onClick={() => {}}
                                className={'btn-submit ml-2'}
                            >
                                Tutorial
                            </Button>
                        </a>
                    )}
                </FlexContainer>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        {goalTypeToday && <Timer />}
                        <Row>
                            <Col md={12}>
                                <h6 style={{ fontStyle: 'italic' }}>
                                    <span className={'mx-3'}>HH</span>
                                    <span className={'mx-3'}>:</span>
                                    <span className={'mx-3'}>MM</span>
                                    <span className={'mx-3'}>:</span>
                                    <span className={'mx-3'}>SS</span>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Input
                                    type="number"
                                    value={hours}
                                    max="18"
                                    min={'0'}
                                    placeholder="HH"
                                    onChange={(e) =>
                                        setHours(Number(e.target.value))
                                    }
                                    style={{
                                        width: '70px',
                                        display: 'inline-block',
                                    }}
                                    className="mx-1"
                                />{' '}
                                :
                                <Input
                                    type="number"
                                    value={min}
                                    max="59"
                                    min={'0'}
                                    placeholder="MM"
                                    onChange={(e) =>
                                        setMin(Number(e.target.value))
                                    }
                                    style={{
                                        width: '70px',
                                        display: 'inline-block',
                                    }}
                                    className="mx-1"
                                />{' '}
                                :
                                <Input
                                    disabled
                                    type="number"
                                    placeholder="SS"
                                    style={{
                                        width: '70px',
                                        display: 'inline-block',
                                    }}
                                    className="mx-1"
                                />
                            </Col>
                        </Row>
                        {!goalTypeToday && (
                            <Row className={'mt-4'}>
                                <Col md={12}>
                                    <h6>Number of Work Days per Week:</h6>
                                    <Input
                                        type="number"
                                        value={workDays}
                                        min="1"
                                        max="7"
                                        placeholder="HH"
                                        onChange={(e) =>
                                            setWorkDays(Number(e.target.value))
                                        }
                                        style={{
                                            width: '70px',
                                            display: 'inline-block',
                                        }}
                                        className="mx-1"
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className="mt-3">
                            <Col md={12}>
                                <h6>{message}</h6>
                            </Col>
                        </Row>
                        {goalTypeToday && (
                            <h6>
                                Recommended to meet weekly goal:{' '}
                                {goalSuggestion}
                            </h6>
                        )}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    className={classnames('mr-3', {
                        'btn-submit': enableSetGoal,
                    })}
                    disabled={!enableSetGoal}
                    onClick={() => switchSetGoal(goalSeconds, workDays)}
                >
                    Set Goal
                </Button>
                <Button
                    color="secondary"
                    onClick={() => handleToggle()}
                    disabled={enforceSetGoal}
                    className={'mr-2'}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </CustomModal>
    );
}

const CustomModal = styled(Modal)`
    text-align: center;
`;

const FlexContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;
