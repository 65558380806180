import React from 'react';
import styled from "styled-components";
import { QUERIES } from '../../contants';

interface Props {
    name: string;
    backgroundColor: string;
}

export default function Subtitle ({
    name,
    backgroundColor
}: Props) {
    return (
        <Title backgroundColor={backgroundColor}>{name}</Title>
    );
}

const Title = styled.p<{backgroundColor: string}>`
    color: black;
    background-color: ${p => p.backgroundColor};
    width: 100%;
    padding-left: 10px;
    margin-bottom: 0px;
    font-size: ${p => p.theme.fontSizes.xl};

    @media ${QUERIES.laptopAndDown} {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;