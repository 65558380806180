import { IUserData } from "../../globals/interfaces";
import moment from 'moment';
import { timeFormatter } from "../../utils";
import _ from "lodash";

const convertToPercent = (decimal: number) => Number((decimal * 100).toFixed());

const parseNaN = (number: number) => isNaN(number) ? 0 : number;

export const resolveProductiveAverageHours = (seconds: number, days: number) => {
    // 3500 seconds in 7 days is 500 seconds per day. convert to timeFormat
    const secondsPerDay = (seconds / days);
    return timeFormatter(secondsPerDay);
}

export const resolveHighestProductiveHours = (userData: IUserData) => {
    const productivityRecords = _.cloneDeep(userData.productivityRecords);
    const sortedProductivityRecords = productivityRecords.sort((a, b) => b.productivityAchieved - a.productivityAchieved);
    const day = sortedProductivityRecords[0];
    const hours = (day.productivityAchieved / 3600).toFixed(2);
    const score = ((day.productivityAchieved / day.productivityGoal) * 100).toFixed(0);
    
    return {
        hours, 
        score
    }
}

export const resolveHighestProductiveScoreSingleDay = (userData: IUserData) => {
    const productivityRecords = _.cloneDeep(userData.productivityRecords);
    const sortedProductivityRecords = productivityRecords.sort((a, b) => {
        return (b.productivityAchieved / b.productivityGoal) - (a.productivityAchieved / a.productivityGoal)
    });
    if (sortedProductivityRecords.length > 0) {
        const day = sortedProductivityRecords[0];
        const highestProductivityScore = (day.productivityAchieved / day.productivityGoal) * 100;
        const description = `${moment(day.createdAt).format('MM/DD/YYYY')}, Productive Time: ${timeFormatter(day.productivityAchieved)}, Goal: ${timeFormatter(day.productivityGoal)}`;
        return {
            highestProductivityScore: highestProductivityScore,
            description
        }
    } else {
        return {
            highestProductivityScore: 0,
            description: ''
        }
    }
}

export const resolveHighestProductiveHoursSingleDay = (userData: IUserData) => {
    const productivityRecords = _.cloneDeep(userData.productivityRecords);
    const sortedProductivityRecords = productivityRecords.sort((a, b) => b.productivityAchieved - a.productivityAchieved);
    if (sortedProductivityRecords.length > 0) {
        const day = sortedProductivityRecords[0];
        const hours = timeFormatter(day.productivityAchieved);
        const score = ((day.productivityAchieved / day.productivityGoal) * 100).toFixed(0);
        return {
            hours,
            description: `${moment(day.createdAt).format('MM/DD/YYYY')}, Productivity Score: ${score}%`
        }
    } else {
        return {
            hours: '00:00:00',
            description: ``
        }
    }
}

export const resolveCumulativeProductivityScore = (userData: IUserData) => {
    const { productivityRecords } = userData;
    const weekDates = [];

    let totalGoal = 0;
    let totalAchieved = 0;
    let weekGoal = 0;
    let weekAchieved = 0;

    for (let i = 7 - 1; i > -1; i--) {
        weekDates.push(moment().subtract(i, 'days').format('L'));
    }

    for (let i = 0; i < productivityRecords.length; i++) {
        const current = productivityRecords[i];
        const date = moment(current.createdAt).format('L');

        totalGoal += current.productivityGoal;
        totalAchieved += current.productivityAchieved;

        if (weekDates.includes(date)) {
            const index = weekDates.indexOf(date);
            weekDates.splice(index, 1);
            weekGoal += current.productivityGoal;
            weekAchieved += current.productivityAchieved;
        }
    }

    const cumulativeScore = parseNaN(totalAchieved / totalGoal);
    const weekScore = parseNaN(weekAchieved / weekGoal);

    return {
        totalProductivityScore: convertToPercent(cumulativeScore),
        weekProductivityScore: convertToPercent(weekScore),
        totalAverageProductiveHours: resolveProductiveAverageHours(totalAchieved, productivityRecords.length),
        weekAverageProductiveHours: resolveProductiveAverageHours(weekAchieved, userData.workDays)
    }
}