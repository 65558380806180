import React, { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IProject } from '../../globals/interfaces';
import ActionIcon from '../ActionIcon';
import useUpdate from '../../hooks/useUpdate';
// import ActionIcon from './ActionIcon';

interface Props {
    project: IProject;
    projectId: string;
    setProjectId: React.Dispatch<React.SetStateAction<string>>;
    viewCompleted: boolean;
}

export default function Project({
    project,
    projectId,
    setProjectId,
    viewCompleted,
}: Props) {
    const [isHovered, setIsHovered] = useState(false);

    const { handleUpdate, markComplete } = useUpdate();

    const handleSelectProject = (project: IProject): void => {
        setProjectId(project._id);
        // setTaskId(defaultTask);
    };

    const isSelectedProject = projectId === project._id;
    const switchTask = false;

    if (!viewCompleted && project.completed) {
        return null;
    }

    return (
        <ProjectNode
            isSelectedProject={isSelectedProject}
            completed={project.completed}
            onClick={() => handleSelectProject(project)}
            variants={variants}
            whileHover={{ scale: 1.01 }}
            switchTask={switchTask}
        >
            <div>
                {!switchTask ? (
                    <i
                        className={classnames(
                            'check-complete-task mx-2 far',
                            {
                                'fa-check-circle':
                                    !project.completed && isHovered,
                            },
                            { 'fa-circle': !project.completed && !isHovered },
                            {
                                'fa-check-circle':
                                    project.completed && !isHovered,
                            },
                            { 'fa-circle': project.completed && isHovered }
                        )}
                        style={{ fontSize: '16px' }}
                        onMouseOver={() => setIsHovered(true)}
                        onMouseOut={() => setIsHovered(false)}
                        onClick={() =>
                            markComplete(
                                'project',
                                !project.completed,
                                project._id
                            )
                        }
                    />
                ) : (
                    <i className={'mx-2'} />
                )}
                {project.name}
            </div>
            <FlexContainer>
                {
                    (!isSelectedProject && project.tasks.length > 0) && (
                        <TaskNumberContainer>
                            <TasksNumber>{project.tasks.length}</TasksNumber>
                        </TaskNumberContainer>
                    )
                }
                {isSelectedProject && project._id !== 'dueToday' && (
                    <ActionIcon
                        type={'edit'}
                        onClick={() => handleUpdate('project', project.name)}
                    />
                )}
            </FlexContainer>
        </ProjectNode>
    );
}

const ProjectNode = styled(motion.div)<{
    isSelectedProject: boolean;
    completed: boolean;
    switchTask?: boolean;
}>`
    font-size: ${(p) => p.theme.fontSizes.md};
    background-color: ${(p) =>
        p.isSelectedProject && !p.switchTask
            ? '#a9a9acc7'
            : p.isSelectedProject && p.switchTask
            ? p.theme.colors.grey300
            : !p.isSelectedProject
            ? null
            : null};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 5px;
    z-index: ${(p) => p.switchTask && 1000};
    text-decoration: ${(p) => (p.completed ? 'line-through' : null)};
    &:hover {
        cursor: pointer;
    }
`;

const FlexContainer = styled.div`
    display: flex;
`;

const TaskNumberContainer = styled.div`
    margin: 0 10px;
    background-color: #929294;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
`;

const TasksNumber = styled.h6`
    
`;

const variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            // y: { velocity: -100,  },
            // type: 'spring'
        },
    },
    closed: {
        y: -50,
        opacity: 0,
        // transition: {
        //     y: { stiffness: 1000 }
        // }
    },
};
