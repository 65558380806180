import styled from "styled-components";

export const PrimaryButton = styled.button.attrs({
    className: 'btn'
})`
    background-color: ${p => p.theme.colors.gold} !important;
    color: black !important;

    &:hover {
        background-color: #dda302da !important;
        color: white !important;
    }
`;