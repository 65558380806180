import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Form, Input } from 'reactstrap';

import { Icon } from '../Icon/index';
import useApi from '../../hooks/useApi';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { projectIdState, projectsState } from '../Projects/atoms';
import { taskIdState } from '../Tasks/atoms';
import _ from 'lodash';
import { userDataState } from '../../App';

type ManageType = 'Projects' | 'Tasks' | 'Subtasks' | 'Notes';

interface Props {
    type: ManageType;
    viewCompleted: boolean;
    setViewCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    switchTask?: boolean;
}

export default function ManageItems({
    type,
    viewCompleted,
    setViewCompleted,
    switchTask
}: Props) {

    const { api } = useApi();
    const userData = useRecoilValue(userDataState);
    const setProjects = useSetRecoilState(projectsState);
    const [projectId, setProjectId] = useRecoilState(projectIdState);
    const [taskId, setTaskId] = useRecoilState(taskIdState);

    const [value, setValue] = useState('');
    const [addItem, setAddItem] = useState(false);
    
    const enableAdd = value.length > 0;

    useEffect(() => {
        if (addItem && (!value || value.length < 0)) {
            setAddItem(false);
        }
    }, [value]);

    const handleAddItem = async (e: any) => {
        e.preventDefault();
        const endpoint = type.toLowerCase().substring(0, type.length - 1);
        const res = await api('post', `/${endpoint}`, {
            name: value,
            userId: userData._id,
            projectId: projectId,
            taskId
        });

        if (type === 'Projects') {
            setProjects(prev => {
                const projectsCopy = _.cloneDeep(prev);
                projectsCopy.push(res.data);
                return projectsCopy;
            });
            setProjectId(res.data._id);
        } else {
            setProjects(prev => {
                const projectsCopy = _.cloneDeep(prev);
                const updateIndex = projectsCopy.findIndex(p => p._id === projectId);
                projectsCopy[updateIndex] = res.data;
                return projectsCopy;
            });
            if (type === 'Tasks') {
                const { tasks } = res.data;
                setTaskId(tasks[tasks.length - 1]._id);
            }
        }
        setValue('');
    }

    return (
        <Container 
            switchTask={switchTask}
        >
            <AnimatePresence>
                {
                    addItem ? (
                        <FormContainer
                            as={motion.div}
                            initial={{ opacity: 0, y: '-100%' }}
                            animate={{ opacity: 1, y: '0%' }}
                            exit={{ opacity: 0, y: '-100%' }}
                            key={'div1'}
                        >
                            <Form onSubmit={(e) => handleAddItem(e)}>
                                <Input 
                                    type={type === 'Notes' ? 'textarea' : 'text'} 
                                    value={value} 
                                    onChange={(e: any) => setValue(e.target.value)}
                                    autoFocus={true} 
                                />
                            </Form>
                            <FormButtonsContainer>
                                <button
                                    className={`mr-2 ${
                                        enableAdd &&
                                        'btn-submit'
                                    }`}
                                    disabled={!enableAdd}
                                    onClick={(e: any) =>
                                        handleAddItem(e)
                                    }
                                >
                                    Confirm
                                </button>
                                <button
                                    color="danger"
                                    onClick={() =>
                                        setAddItem(false)
                                    }
                                >
                                    Cancel
                                </button>
                            </FormButtonsContainer>
                        </FormContainer>
                    ) :  (
                        <ActionContainer
                            as={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0 }}
                            key={'div1'}
                        >
                            <PlusIcon
                                className="fas fa-plus hover"
                                onClick={() => setAddItem(true)}
                                as={motion.i}
                                whileTap={{ rotate: 90 }}
                                type={type}
                            />
                            {
                                type !== 'Notes' && (
                                    <ShowCompletedContainer
                                        onClick={() => setViewCompleted((prev: boolean) => !prev)}
                                    >
                                        {viewCompleted ? 'Hide' : 'Show'} Completed {type}
                                    </ShowCompletedContainer>
                                )
                            }
                        </ActionContainer>
                    )
                }
            </AnimatePresence>
        </Container>
    );
}

const Container = styled.div<{
    switchTask?: boolean
}>`
    /* visibility: ${p => (p.switchTask) ? 'show' : 'hidden'};
    opacity: ${p => (p.switchTask) ? 1 : 0};
    transition: 300ms; */
`;

const ActionContainer = styled.div`
    --font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
`;

const FormContainer = styled.div``;

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
`;

const Spacer = styled.div`
    height: 47px;
`;

const PlusIcon = styled(Icon)<{type: ManageType}>`
    color: ${p => ['Notes', 'Subtasks'].includes(p.type) ? 'white' : 'black'};
    font-size: var(--font-size);
`;

const ShowCompletedContainer = styled.div`
    font-size: var(--font-size);
    &:hover {
        cursor: pointer;
    }
`;