import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';

// import Attachments from '../Attachments';
import Subtask from '../Subtask';
import {
    INote,
    ISubtask,
    IMap,
    ITask,
    ISaveAttachment,
} from '../../globals/interfaces';

import Subtitle from '../Subtitle';
import ManageItems from '../ManageItems';
import { theme } from '../../Theme';
import SlideUpContainer from '../SlideUpContainer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { taskIdState, taskSelector } from '../Tasks/atoms';
import useApi from '../../hooks/useApi';
import {
    projectSelector,
    projectsSelector,
    reminderTasksSelector,
    updateProjectsSelector,
} from '../Projects/atoms';
import ListItem from '../ListItem';
import useUpdate from '../../hooks/useUpdate';
import ManageContainerBase from '../ManageContainer';
import Attachments from '../Attachments';

export default function TaskDetails() {
    const { api } = useApi();
    const updateProjects = useSetRecoilState(updateProjectsSelector);
    const selectedProject = useRecoilValue(projectSelector);
    const selectedTask = useRecoilValue(taskSelector);
    const { handleUpdate } = useUpdate();

    const [viewCompletedsubtasks, setViewCompletedsubtasks] =
        useState<boolean>(false);

    // Update Tasks
    const [dueDate, setDueDate] = useState<string>('');
    const [reminderDate, setReminderDate] = useState<string>('');

    useEffect(() => {
        setDueDate(
            selectedTask.dueDate
                ? moment(selectedTask.dueDate).format('YYYY-MM-DDTHH:mm')
                : ''
        );
        setReminderDate(
            selectedTask.reminderDate
                ? moment(selectedTask.reminderDate).format('YYYY-MM-DDTHH:mm')
                : ''
        );
    }, [selectedTask]);

    const setTaskDueAndReminderDate = async (
        date: string,
        projectId: string,
        taskId: string,
        reminder: boolean
    ): Promise<void> => {
        let endpoint;
        const data: IMap<string> = {
            projectId,
            taskId,
        };
        if (reminder) {
            data.reminderDate = date;
            endpoint = '/task/reminder-date';
        } else {
            data.dueDate = date;
            endpoint = '/task/due-date';
        }

        const response = await api('put', endpoint, { data });

        if (response.successful) {
            const { data } = response;
            updateProjects(data);
        }
    };

    const handleSetDate = (date: string, reminder: boolean) => {
        setTaskDueAndReminderDate(
            date,
            selectedTask.projectId,
            selectedTask._id,
            reminder
        );
    };

    const saveAttachments = async (
        attachments: Array<ISaveAttachment>
    ): Promise<boolean> => {
        console.log(attachments);
        const res = await api('post', '/attachment', {
            data: {
                projectId: selectedProject._id,
                taskId: selectedTask._id,
                attachments,
            },
        });

        if (res.successful) {
            updateProjects(res.data);
            return true;
        } else {
            return false;
        }
    };

    const deleteAttachment = async (_id: string): Promise<void> => {
        const res = await api('delete', `/attachment/${_id}`);
        if (res.successful) {
            const attachments = selectedTask?.attachments.filter(
                (t) => t._id !== _id
            );
            const projectCopy = _.cloneDeep(selectedProject);
            const task = projectCopy.tasks.find(
                (t) => t._id === selectedTask._id
            );
            task!.attachments = attachments;
            updateProjects(projectCopy);
        }
    };

    const sortedSubtasks = [...selectedTask.subtasks];
    sortedSubtasks.sort((a, b) => +a.completed - +b.completed);

    return (
        <Container>
            <Subtitle name={'Details'} backgroundColor={theme.colors.greyAlt} />
            <ScrollContainer>
                <div>
                    <div>
                        <Heading>Subtasks</Heading>
                        <SlideUpContainer useKey={selectedTask._id}>
                            {sortedSubtasks.map((st: ISubtask, i: number) => (
                                <Subtask
                                    subtask={st}
                                    taskId={selectedTask._id}
                                    projectId={selectedProject._id}
                                    viewCompletedsubtasks={
                                        viewCompletedsubtasks
                                    }
                                    key={i}
                                />
                            ))}
                        </SlideUpContainer>
                    </div>
                    <ManageContainer>
                        <ManageItems
                            type={'Subtasks'}
                            viewCompleted={viewCompletedsubtasks}
                            setViewCompleted={setViewCompletedsubtasks}
                        />
                    </ManageContainer>
                </div>
                <div>
                    <Heading>Set Due Date</Heading>
                    <DateInput
                        type="datetime-local"
                        className="mb-2"
                        value={dueDate}
                        onChange={(e: any) =>
                            handleSetDate(
                                moment(e.target.value)
                                    .utc()
                                    .format('MM/DD/YYYY HH:mm'),
                                false
                            )
                        }
                    />
                    <Heading>Set Reminder</Heading>
                    <DateInput
                        type="datetime-local"
                        className="mb-2"
                        value={reminderDate}
                        onChange={(e: any) =>
                            handleSetDate(
                                moment(e.target.value)
                                    .utc()
                                    .format('MM/DD/YYYY HH:mm'),
                                true
                            )
                        }
                    />
                </div>
                <div className="div-notes-list">
                    <div>
                        <Heading>Notes</Heading>
                        <SlideUpContainer useKey={selectedTask._id}>
                            {selectedTask.notes.map((n: INote) => (
                                <ListItem
                                    key={n._id}
                                    text={n.name}
                                    handleUpdate={() =>
                                        handleUpdate('note', n.name, n._id)
                                    }
                                />
                            ))}
                        </SlideUpContainer>
                    </div>
                    <ManageContainer>
                        <ManageItems
                            type={'Notes'}
                            viewCompleted={false}
                            setViewCompleted={() => false}
                            switchTask={false}
                        />
                    </ManageContainer>
                </div>
                <Attachments
                    selectedTask={selectedTask}
                    attachments={selectedTask.attachments}
                    saveAttachments={saveAttachments}
                    deleteAttachment={deleteAttachment}
                />
            </ScrollContainer>
        </Container>
    );
}

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ScrollContainer = styled.div`
    overflow-y: scroll !important;
    overflow-x: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: #677077;
    color: white;
`;

const Heading = styled.h5`
    font-size: ${(p) => p.theme.fontSizes.lg};
`;

const DateInput = styled.input`
    width: 250px;
`;

const NotesFormContainer = styled.div`
    margin-top: 5px;
`;

const ManageContainer = styled(ManageContainerBase)`
    ${Container}:hover & {
        opacity: 1;
    }
`;
