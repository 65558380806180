import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

import Subtitle from "../Subtitle";
import { Icon } from "../Icon";
import { QUERIES } from '../../contants';

interface Props {
    showProjectsList: boolean;
    setShowProjectsList: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProjectsHeader ({
    showProjectsList,
    setShowProjectsList
}: Props) {

    const icon = showProjectsList ? 
        'fas fa-caret-right' :
        'fas fa-caret-down';

    return (
        <HeaderContainer>
            <Subtitle 
                name={'Projects'}
                backgroundColor={'#e9e6e6'}
            />
            <ExpandIcon 
                className={icon}
                as={motion.i} 
                variants={variants}
                animate={showProjectsList ? 'rotate' : 'rotateReverse'}
                onClick={() => setShowProjectsList(prev => !prev)}
            />
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #e9e6e6;
    padding-right: 10px;
    width: 100%;
`;

const ExpandIcon = styled(Icon)`
    display: none;

    @media ${QUERIES.laptopAndDown} {
        color: black;
        display: flex;
        &:hover {
            cursor: pointer;
            color: white;
        }
    }
`;

const variants = {
    rotate: { rotate: 90, transition: { duration: 0.5 } },
    rotateReverse: { rotate: -90, transition: { duration: 0.5 } }
};