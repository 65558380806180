import { motion } from 'framer-motion';
import React from 'react';
import styled from "styled-components";

interface Props {
    children: React.ReactNode;
    switchTask?: boolean;
    onClick?: () => any;
}

export default function Backdrop ({
    children,
    switchTask,
    onClick
}: Props) {
    return (
        <Container
            as={motion.div}
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            switchTask={switchTask}
        >
            {children}
        </Container>
    );
}

const Container = styled.div<{switchTask?: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    height: ${p => p.switchTask ? '0%' : '100%'};;
    width: 100%;
    background: #000000b9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;
