import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { slideUpChild } from '../../assets/animations';
import ActionIcon from '../ActionIcon';

interface Props {
    key: string;
    text?: string;
    handleUpdate?: (props?: any) => any;
    open?: boolean;
    children?: React.ReactNode;
    handleClick?: (props?: any) => any;
}

export default function ListItem ({
    text,
    key,
    handleUpdate,
    open,
    children,
    handleClick
}: Props) {

    if (open) {
        return (
            <Item
                as={motion.li}
                key={key}
                variants={slideUpChild}
                onClick={handleClick}
            >   
                <FlexContainer>
                    {children}
                </FlexContainer>
            </Item>
        );
    }

    return (
        <Item
            as={motion.li}
            key={key}
            variants={slideUpChild}
        >   
            <div>
                <ListIcon className="fas fa-circle" />
                {text}
            </div>
            <ActionIcon 
                type={'edit'}
                onClick={() => handleUpdate && handleUpdate()}
                color={'white'}
                hoverColor={'white'}
            />
        </Item>
    );
}

const Item = styled.li`
    display: flex;
    justify-content: space-between;
`;

const ListIcon = styled.i`
    /* font-size: 8px; */
    padding-right: 10px;
`;

const EditIcon = styled.i`
    opacity: 0;
    transition: 0.3s;

    ${Item}:hover & {
        opacity: 1;
        transition: 0.3s;
        cursor: pointer;
    }
`;

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    &:hover {
        cursor: pointer;
    }
`;