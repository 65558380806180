import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ProjectsContainer } from './ProjectsContainer';
import Project from './Project';
import { IProject } from '../../globals/interfaces';
import ManageItems from '../ManageItems';
import { projectIdState, projectsSelector } from './atoms';
import ManageContainerBase from '../ManageContainer';

export default function ProjectsList () {

    const projects = useRecoilValue(projectsSelector);
    const [projectId, setProjectId] = useRecoilState(projectIdState); 
    const [viewCompleted, setViewCompleted] = useState(false);

    // if (projects.length < 2) {
    //     setIsOpen(true);
    // }

    // useEffect(() => {
    //     if (isOpen) {
    //         if (currentStep === 0 && projects.length > 1) {
    //             setCurrentStep((prev) => prev + 1);
    //         } else if (currentStep === 1) {
    //             const addedProject = projects[1];
    //             if (addedProject.tasks.length > 0) {
    //                 setCurrentStep((prev) => prev + 1);
    //             }
    //         }
    //     }
    // }, [isOpen, currentStep, setCurrentStep, projects]);

    return (
        <ProjectsListOuterContainer 
            data-testid={'div-project-list'}
        >
            <ProjectsListInnerContainer 
                as={motion.div} 
                initial={'closed'}
                animate={'open'}
                variants={variants}
            >
                <ListContainer>
                    {
                        projects.map((project: IProject, i: number) => 
                            <Project
                                key={i}
                                project={project}
                                projectId={projectId}
                                setProjectId={setProjectId}
                                viewCompleted={viewCompleted}
                            />
                        )
                    }
                </ListContainer>
                <ManageContainer>
                    <ManageItems 
                        type={'Projects'}
                        viewCompleted={viewCompleted}
                        setViewCompleted={setViewCompleted}
                    />
                </ManageContainer>
            </ProjectsListInnerContainer>
        </ProjectsListOuterContainer>
    );
}

const ProjectsListOuterContainer = styled.div`
    height: 100%;
    background-color: #b5b5b7;
    width: 100%;
`;

const ProjectsListInnerContainer = styled.div`
    /* overflow-x: hidden; */
    padding-top: 10px;
`;

const ListContainer = styled.div<{viewCompleted?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 5px;
    display: ${p => p.viewCompleted === false ? 'none' : 'flex'};
`;

const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const ManageContainer = styled(ManageContainerBase)`
    margin: 0 5px;
    
    ${ProjectsContainer}:hover & {
        opacity: 1;
    }
`;

// https://codesandbox.io/s/framer-motion-side-menu-mx2rw?from-embed=&file=/src/MenuItem.tsx:524-551
// https://www.framer.com/docs/examples/