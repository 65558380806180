import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { motion } from 'framer-motion';

// Components
// import StatsDetailsContainer from './StatsDetailsContainer';
import {
    IProductivityRecord,
    IMap,
    IGraphData,
} from '../../globals/interfaces';
//import ModalStatDetails from './ModalStatDetails';
import _ from 'lodash';
import { formatPercent, timeFormatter } from '../../utils';
import styled from 'styled-components';

interface Props {
    productivityRecords: Array<IProductivityRecord>;
    activeTab: string;
    refreshKey: number;
}

interface ILabel {
    x: any;
    y: number | null;
    z: string;
    a: string;
    b: string;
}

interface IValue {
    Achieved: string;
    Goal: string;
    x: any;
    y: any;
}

const defaultValue = {
    Achieved: '0',
    Goal: '0',
    x: '',
    y: '',
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const decimalToTimeformat = (raw: number) => timeFormatter(raw * 3600);

export default function StatsGraph({
    productivityRecords,
    activeTab,
    refreshKey,
}: Props) {
    const [value, setValue] = useState<IValue>(defaultValue);

    // useEffect(() => {
    //     // if (activeTab === '1') {
    //     //     setKey(key + 1);
    //     // }
    //     setKey(key + 1);
    // }, [activeTab]);

    const [graphData, setGraphData] = useState<IGraphData>({
        labels: [],
        datasets: [],
    });

    const [weekStats, setWeekStats] = useState({
        productivityAchieved: 0,
        productivityGoal: 0,
    });

    useEffect(() => {
        const getStats = (range = 7) => {
            const weekDates = [];
            const stats = [];

            for (let i = range - 1; i > -1; i--) {
                weekDates.push(moment().subtract(i, 'days').format('L'));
            }

            for (let i = 0; i < weekDates.length; i++) {
                const stat: IMap<any> = {
                    date: weekDates[i],
                };

                for (
                    let j = productivityRecords.length - 1;
                    j > productivityRecords.length - range;
                    j--
                ) {
                    if (productivityRecords[j]) {
                        if (
                            moment(productivityRecords[j].createdAt).format(
                                'L'
                            ) === weekDates[i]
                        ) {
                            stat.productivityData = productivityRecords[j];
                        }
                    }
                }

                stats.push(stat);
            }

            const goalsArray = [];
            const achievedArray = [];
            let productivityAchieved = 0,
                productivityGoal = 0;

            console.log(stats);

            for (let i = 0; i < stats.length; i++) {
                const stat = stats[i];
                const goal = _.get(
                    stat,
                    'productivityData.productivityGoal',
                    0
                );
                const achieved = _.get(
                    stat,
                    'productivityData.productivityAchieved',
                    0
                );

                goalsArray.push(goal / 3600);
                achievedArray.push(achieved / 3600);

                productivityGoal += goal;
                productivityAchieved += achieved;
            }

            const data = {
                labels: weekDates,
                datasets: [
                    {
                        label: 'Goal',
                        data: goalsArray,
                        backgroundColor: 'grey',
                        borderWidth: 2,
                        borderColor: 'black',
                        hoverOffset: 4,
                    },
                    {
                        label: 'Achieved',
                        data: achievedArray,
                        backgroundColor: '#f2b632',
                        borderWidth: 2,
                        borderColor: 'black',
                        hoverOffset: 4,
                    },
                ],
            };

            setGraphData(data);
            setWeekStats((prev) => {
                const copy = { ...prev };
                copy.productivityAchieved = productivityAchieved;
                copy.productivityGoal = productivityGoal;
                return copy;
            });
        };

        productivityRecords && getStats();
    }, [productivityRecords]);

    const tooltip = {
        callbacks: {
            label: function (context: any) {
                return `${context.dataset.label}: ${decimalToTimeformat(
                    context.raw
                )}`;
            },
            footer: function (contextArray: Array<any>) {
                const context = contextArray[0] || {};
                const { dataIndex } = context;
                const { datasets } = graphData;
                const goals = datasets[0];
                const achieved = datasets[1];
                const decimal =
                    achieved.data[dataIndex] / goals.data[dataIndex];
                if (context.dataset && context.dataset.label === 'Achieved') {
                    return `Score: ${(decimal * 100).toFixed()}%`;
                }
                return '';
            },
        },
    };

    console.log(weekStats);

    return (
        <Container
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            key={activeTab}
        >
            <Row>
                <Col md={12}>
                    <h6 className="stats-subtitle ml-2 mt-2">
                        Stats for{' '}
                        {`${moment()
                            .subtract(6, 'days')
                            .format('dddd LL')} - ${moment().format(
                            'dddd LL'
                        )}`}
                    </h6>
                    <CustomH6>
                        Productivity Time: {timeFormatter(weekStats.productivityAchieved, true)}, 
                        Productivity Score{' '}
                        {formatPercent(
                            (weekStats.productivityAchieved /
                                weekStats.productivityGoal) *
                                100
                        )}
                    </CustomH6>
                </Col>
            </Row>
            <GraphContainer>
                <Bar
                    data={graphData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: true,
                                text: 'Data in hours',
                            },
                            tooltip,
                        },
                    }}
                />
            </GraphContainer>

            {/* <ModalStatDetails 
                modal={showDetails}
                setModal={setShowDetails}
                record={detailsRecord}
            /> */}
        </Container>
    );
}

const Container = styled.div`
    padding: 0px 10px;
`;

const GraphContainer = styled.div`
    height: calc(100vh - 220px);
`;

const CustomH6 = styled.h6`
    margin-bottom: 0px;
`;
