import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
    colors: {
        gold: '#f2b632',
        darkBlue: '#252839',
        grey50: '#f5f4f4',
        grey100: '#e9e6e6',
        grey200: 'rgba(204, 204, 204, 0.8)e',
        grey300: '#677077',
        greyAlt: 'hsl(206deg 7% 60%)'
    },
    fontSizes: {
        sm: '1rem',
        md: '1.125rem',
        lg: '1.25rem',
        xl: '1.5rem',
        xxl: '1.75rem'
    }
}

export default function Theme ({ children }: { children: React.ReactNode }) {
    return (
        <ThemeProvider
            theme={theme}
        >
            {children}
        </ThemeProvider>
    );
}