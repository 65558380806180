import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import history from "../../utils/history";
import { CircularProgressbarWithChildren, buildStyles   } from 'react-circular-progressbar';

import CustomRow from './CustomRow';
import useScroll from './useScroll';
import styled from 'styled-components';
import { PrimaryButton } from '../Buttons';

export default function Landing () {

    const {
        isAuthenticated,
        loginWithRedirect
    } = useAuth0();   

    const { scrollPercent } = useScroll();
    
    if (isAuthenticated) {
        history.push('/todos');
    }

    const testNotification = () => {
        new Notification('Todo Plus', {
            body: 'Productivity Session Completed!'
        });
    }

    return (
        // @ts-ignore
        <Container fluid className={'text-center'} style={{ backgroundColor: 'white' }}>
            <StickyHeader>
                <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbarWithChildren 
                        strokeWidth={5}
                        value={scrollPercent}
                        background
                        backgroundPadding={6}
                        styles={buildStyles({
                            pathColor: `#f2b632`,
                            pathTransitionDuration: 0.5,
                            backgroundColor: "#e9e6e6",
                            strokeLinecap: "round"
                        })}
                    >
                        <img src={require('../../assets/img/icon-gold.svg').default} style={{ width: '200px' }} />
                    </CircularProgressbarWithChildren>
                </div>
                <h1>
                    Todo Plus
                </h1>
                <div>
                    <PrimaryButton 
                        onClick={() => loginWithRedirect()}
                        style={{ fontSize: '24px' }}
                    >
                        Log in / Sign up
                    </PrimaryButton>
                </div>
            </StickyHeader>
            <CustomRow backgroundColor={'#eae6e5'}>
                <div className={''}>
                    <h1 style={{ fontSize: '75px' }}>
                        Supercharge your productivity.
                    </h1>
                    <h3 className={'mt-4'}>
                        Designed to help you focus and do more everyday - <span className={'font-weight-bold bg-gold'}>for free</span>.
                    </h3>
                    <h3>
                        No "pro/paid" plans and no ads.
                    </h3>
                    <PrimaryButton 
                        onClick={() => loginWithRedirect()} 
                        style={{ fontSize: '24px', marginTop: '20px' }}
                    >
                        Get Started
                    </PrimaryButton>
               </div>
            </CustomRow>
            <CustomRow backgroundColor={'white'}>
                <div>   
                    <h3>
                        Productivity Sessions: stay focused on your tasks and fill up your productivity meter.
                    </h3>
                    <FullWidthImg src={require('../../assets/img/session.jpg')} />
               </div>
            </CustomRow>
            <CustomRow backgroundColor={'#eae6e5'}>
                <div>   
                    <h3>
                        Detailed Stats: view your productivity over time.
                    </h3>
                    <FullWidthImg src={require('../../assets/img/stats.jpg')} />
               </div>
            </CustomRow>
            <CustomRow backgroundColor='white'>
                <div>
                    <h3>
                        Learn more:
                    </h3>
               </div>
                <div className={'text-center'}>
                    <iframe className={'embedded-video-16-9'} src="https://www.youtube.com/watch?v=yG_Pq3Zoidk" title="Todo Plus" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
               </div>
            </CustomRow>
        </Container>
    );
}

const Container = styled.div`

`;

const StickyHeader = styled.div`
    --padding-x: 10px;
    position: sticky; 
    top: 0;
    background-color: white;
    z-index: 99999;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
`;

const FullWidthImg = styled.img`
    max-width: 100%;
`;