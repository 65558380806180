import React, { useState, useEffect } from 'react';
import { CircularProgressbarWithChildren, buildStyles   } from 'react-circular-progressbar';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface Props {
    percentage: boolean,
    value: number | string,
    name: string,
    description: string
}

export default function PercentageStat ({
    percentage,
    value,
    name,
    description
}: Props) {

    return (
        <Card
            as={motion.div}
            variants={variants}
        >
            <ProgressBarContainer>
                <CircularProgressbarWithChildren 
                    strokeWidth={percentage ? 10 : 0}
                    background={!percentage}
                    value={Number(value)}
                    styles={buildStyles({
                        pathColor: `#f2b632`,
                        trailColor: 'white',
                        pathTransitionDuration: 0.5,
                        backgroundColor: 'white'
                    })}
                >
                    <h5>
                        { value }{percentage && '%'}
                    </h5>
                </CircularProgressbarWithChildren>
            </ProgressBarContainer>
            <DescriptionContainer>
                <h5>
                    {name} 
                    <br />
                    {description}
                </h5>
            </DescriptionContainer>
        </Card>
    );
}

const Card = styled.div`
    height: 180px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 100px 10px;
    background-color: #e9e6e6;
    border-radius: 10px;

    &:last-of-type {
        margin-bottom: 10px;
    }
`;

const ProgressBarContainer = styled.div`
    flex-basis: 180px;
    flex-shrink: 1;
`;

const DescriptionContainer = styled.div`
    flex-shrink: 2;
`;

const variants = {
    open: {
        y: 0,
        opacity: 1
    },
    closed: {
        y: -50,
        opacity: 0
    }
};