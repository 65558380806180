import useSound from 'use-sound';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import useApi from './useApi';
import {
    IMap,
    UpdateItem,
    UpdateType,
    HandleUpdate,
    MarkComplete,
} from '../globals/interfaces';
import { deleteProjectSelector, projectIdState, projectSelector, updateProjectsSelector } from '../components/Projects/atoms';
import { taskIdState } from '../components/Tasks/atoms';
import {
    modalUpdateState,
    subtaskNoteIdState,
    updateTypeState,
    updateValueState,
} from '../components/ModalUpdate/updateAtoms';

const complete = require('../assets/audio/complete.mp3');

export default function useUpdate() {
    const { api } = useApi();
    const updateProjects = useSetRecoilState(updateProjectsSelector);
    const deleteProject = useSetRecoilState(deleteProjectSelector);
    const setModalUpdate = useSetRecoilState(modalUpdateState);
    const [updateType, setUpdateType] = useRecoilState(updateTypeState);
    const [updateValue, setUpdateValue] = useRecoilState(updateValueState);
    const [subtaskNoteId, setSubtaskNoteId] =
        useRecoilState(subtaskNoteIdState);

    const selectedProject = useRecoilValue(projectSelector);
    const [projectId, setProjectId] = useRecoilState(projectIdState);
    const taskId = useRecoilValue(taskIdState);

    const [playCompleteSound] = useSound(complete, {
        volume: 0.25,
    });

    const handleUpdate: HandleUpdate = (
        updateType: UpdateType,
        updateValue: string,
        subtaskNoteId?: string
    ) => {
        setUpdateType(updateType);
        setUpdateValue(updateValue);
        if (subtaskNoteId) {
            setSubtaskNoteId(subtaskNoteId);
        }
        setModalUpdate(true);
    };

    const updateItem: UpdateItem = async (
        e: Event,
        action: 'update' | 'delete'
    ): Promise<void> => {
        e.preventDefault();

        if (action === 'delete') {
            let queries = `?projectId=${projectId}&taskId=${taskId}`;
            if (updateType === 'subtask') {
                queries += `&subtaskId=${subtaskNoteId}`;
            } else if (updateType === 'note') {
                queries += `&noteId=${subtaskNoteId}`;
            }
            const res = await api('delete', `/${updateType}${queries}`);

            if (res.successful) {
                setModalUpdate(false);

                if (updateType === 'project') {
                    deleteProject(projectId);
                } else {
                    updateProjects(res.data);
                }
            }
        } else {
            setModalUpdate(false);

            const data: IMap<any> = {
                name: updateValue,
                projectId: projectId,
            };

            if (updateType === 'task') {
                data.taskId = taskId;
            } else if (updateType === 'subtask') {
                data.taskId = taskId;
                data.subtaskId = subtaskNoteId;
            } else if (updateType === 'note') {
                data.taskId = taskId;
                data.noteId = subtaskNoteId;
            }

            const res = await api('put', `/${updateType}`, { data });

            if (res.successful) {
                updateProjects(res.data);

                if (updateType === 'task') {
                } else if (updateType === 'subtask') {
                    // setTaskId((prev) => {
                    //     const updateTask = _.cloneDeep(prev);
                    //     const updateSubtask = updateTask.subtasks.find(
                    //         (subtask: ISubtask) => subtask._id === subtaskNoteId
                    //     );
                    //     updateSubtask!.name = updateValue;
                    //     return updateTask;
                    // });
                } else {
                    // setTaskId((prev) => {
                    //     const updateTask = _.cloneDeep(prev);
                    //     const updateNote = updateTask.notes.find(
                    //         (n: INote) => n._id === subtaskNoteId
                    //     );
                    //     updateNote!.name = updateValue;
                    //     return updateTask;
                    // });
                }
            }
        }
    };

    const markComplete: MarkComplete = async (
        updateType: UpdateType,
        completed: boolean,
        projectId: string,
        taskId?: string,
        subtaskId?: string
    ): Promise<void> => {
        const data: IMap<any> = {
            projectId,
            taskId,
            subtaskId,
            completed,
        };

        const endpoint = `/${updateType}/complete`;

        const res = await api('put', endpoint, { data });

        if (res.successful) {
            updateProjects(res.data);

            if (updateType === 'project' && completed && projectId === selectedProject._id) {
                setProjectId('');
            } else if (updateType === 'project' && !completed) {
                setProjectId(projectId);
            }

            if (completed) {
                playCompleteSound();
            }
        }
    };

    return {
        handleUpdate,
        updateItem,
        markComplete,
    };
}
