import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Tooltip } from 'reactstrap';

interface Props {
    className: string;
    onClick: (props?: any) => any;
    dataTip: string;
    id: string;
    hoverColor?: string;
}

export default function TimerIcon ({
    className,
    onClick,
    dataTip,
    id,
    hoverColor
}: Props) {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <Tooltip 
                isOpen={tooltipOpen}
                target={id}
                toggle={toggle}
            >
                {dataTip}
            </Tooltip>
            <Icon 
                as={motion.i}
                whileHover={{ scale: 1.2 }}
                className={className}
                onClick={onClick}
                id={id}
                hoverColor={hoverColor}
            />
        </>
    );
}

const Icon = styled.i<{hoverColor?: string}>`
    font-size: ${p => p.theme.fontSizes.xl};
    
    &:hover {
        cursor: pointer;
        -webkit-text-fill-color: ${p => p.hoverColor ? p.hoverColor : p.theme.colors.gold};
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
    }
`;